<template>
  <a-modal :visible="shown"
           :title="title"
           :confirm-loading="loading"
           :mask-closable="true"
           :centered="true"
           destroy-on-close
           :after-close="afterClosed"
           @ok="onSubmit"
           @cancel="close()">
    <a-form-model ref="formRef"
                  :model="form"
                  :rules="rules"
                  :label-col="{span: 8}"
                  :wrapper-col="{span: 16}"
                  label-align="right"
                  class="form-model flex-grow">
      <a-form-model-item label="用户名" prop="username" class="form-item">
        <a-input ref="userNameRef"
                 v-model="form.username"
                 :disabled="!!id"
                 placeholder="请填写用户名"/>
      </a-form-model-item>

      <a-form-model-item label="能看到的前台菜单" prop="look_at" class="form-item">
        <a-select v-model="form.look_at" mode="multiple" placeholder="请选择能看到的前台菜单">
          <a-select-option v-for="(item) in teacherAccessedMenu" :key="item.id" :value="item.id">
            {{ item.title }}
          </a-select-option>
        </a-select>
      </a-form-model-item>
    </a-form-model>
  </a-modal>
</template>

<script>
import {
  getTeacherAccessedMenu,
  getTeacherDetail,
  saveTeacher,
} from '../api';

export default {
  name: 'EditTeacherModal',
  data() {
    return {
      loading: false,
      shown: false,

      id: '',
      title: '',

      teacherAccessedMenu: [],

      form: {
        username: '',
        look_at: [],
      },

      rules: {
        username: [{ required: true, trigger: 'blur', message: '请填写用户名' }],
        look_at: [{ required: true, trigger: 'change', message: '请选择能看到的前台菜单' }],
      },
    };
  },
  methods: {
    show({ id } = {}) {
      this.id = id;
      this.title = id ? `修改讲师` : `添加讲师`;

      this.shown = true;

      this.getTeacherAccessedMenu();
      this.getData();
      this.$nextTick(() => {
        this.$refs.userNameRef?.focus();
      });
    },
    close(evt) {
      this.$emit('closed', evt);
      this.shown = false;
    },
    afterClosed() {
      this.id = '';
      this.form = {
        username: '',
        look_at: [],
      };
    },

    async getData() {
      if (this.loading || !this.id) {
        return;
      }
      this.loading = true;

      const data = await getTeacherDetail(this.id).finally(() => {
        this.loading = false;
      });
      if (!data || data.error_code) {
        this.$message.error(data?.message || '获取讲师详情失败');
        return;
      }
      const result = data?.data || {};
      this.form = {
        username: result.created_user_data.username || undefined,
        look_at: result.look_at ? result.look_at.split(',').filter(
          (i) => !!i,
        ).map(
          (i) => +i,
        ) : [],
      };
    },
    async getTeacherAccessedMenu() {
      if (this.teacherAccessedMenu?.length) {
        return;
      }

      const data = await getTeacherAccessedMenu();
      this.teacherAccessedMenu = data?.data || [];
    },

    validate() {
      return new Promise((resolve, reject) => {
        this.$refs.formRef.validate((validated) => {
          if (validated) {
            resolve(true);
          } else {
            reject(false);
          }
        });
      });
    },
    onSubmit() {
      this.validate().then(() => {
        this.onSave();
      });
    },
    async onSave() {
      if (this.loading) {
        return;
      }
      this.loading = true;

      const params = {
        ...this.form,
        look_at: this.form.look_at.join(','),
      };

      const data = await saveTeacher(params).finally(() => {
        this.loading = false;
      });
      if (!data || data.error_code) {
        this.$message.error(data?.message || '保存失败');
        return;
      }
      this.$message.success(data.message || '保存成功');

      this.close({ saved: true, created: !params.id });
    },
  },
};
</script>

<style scoped lang="scss">

</style>
