/**
 *
 */
import {
  getCategoriesSelectList,
  getTestPaperList,
} from '@/api/config';
import { getPagination } from '@components/Pagination';

export const ConfigMixin = {
  data() {
    return {
      selectList: [],
      testPaperList: [],
      next: false,
      pagination: getPagination(),
    };
  },
  computed: {
    isEdit() {
      return this.id && this.id !== 'undefined' && this.id !== 'null';
    },
    id() {
      return this.$route.query.id;
    },
  },
  created() {
    !this.selectList.length && this.getSelectList();
    !this.testPaperList.length && this.getTestPaperList();
  },
  methods: {
    async getSelectList() {
      this.loading = true;
      const data = await getCategoriesSelectList().finally(() => this.loading = false);
      if (!data || data.error_code) {
        return;
      }
      if (data && data._status_code === 200) {
        this.selectList = data.data;
      }
    },
    async getTestPaperList() {
      this.loading = true;
      const data = await getTestPaperList().finally(() => this.loading = false);
      if (!data || data.error_code) {
        return;
      }
      if (data && data._status_code === 200) {
        this.testPaperList = data.data;
      }
    },
    deleteData(record) {
      this.$confirm({
        title: '提示',
        content: '确定删除当前数据?',
        onOk: () => {
          this.delApiData(record);


        },
        onCancel() {
        },
      });
    },
    saveAndNext() {
      this.next = true;
      this.handleSubmit();
    },
    goNextStep(name, id) {
      let query = id ? { id } : this.$route.query;
      if (this.next) {
        this.$router.push({
          path: name,
          query,
        });
      }
      this.next = false;
    },
    disabledDate() {
      // Can not select days before today and today
      // return current && current < moment().endOf('day');
      return false;
    },
    disabledDateTime() {
      // return {
      //   disabledHours: () => this.range(0, 24).splice(4, 20),
      //   disabledMinutes: () => this.range(30, 60),
      //   disabledSeconds: () => [55, 56],
      // };
      return {
        disabledHours: [],
        disabledMinutes: [],
        disabledSeconds: [],
      };
    },
    disabledRangeTime(_, type) {
      if (type === 'start') {
        return {
          disabledHours: () => this.range(0, 60).splice(4, 20),
          disabledMinutes: () => this.range(30, 60),
          disabledSeconds: () => [55, 56],
        };
      }
      return {
        disabledHours: () => this.range(0, 60).splice(20, 4),
        disabledMinutes: () => this.range(0, 31),
        disabledSeconds: () => [55, 56],
      };
    },
    range(start, end) {
      const result = [];
      for (let i = start; i < end; i++) {
        result.push(i);
      }
      return result;
    },
    onPageChange(page, pageSize) {
      this.pagination.current = page;
      this.pagination.pageSize = pageSize;
      this.handleTableChange();
    },
    onSizeChange(current, size) {
      this.pagination.pageSize = size;
      this.handleTableChange(1, size);
    },
  },
};
