<template>
  <a-modal :visible="shown"
           title="请选择班次"
           :mask="true"
           :mask-closable="true"
           :confirm-loading="loading"
           @ok="save"
           @cancel="close">
    <a-form>
      <a-form-item>
        <SelectBase v-model="classId"
                    mode="default"
                    :list="list"
                    :options="{id: 'id', name: 'title'}"
                    :allow-select-all="false"
                    placeholder="请选择班次"/>
      </a-form-item>
    </a-form>
  </a-modal>
</template>

<script>
import {
  getAdjustClassList,
  saveAdjustClassList,
} from '../api/sign.up';
import SelectBase from '@/components/select/SelectBase';

export default {
  name: 'SelectClassModal',
  components: {
    SelectBase,
  },
  data() {
    return {
      loading: false,
      shown: false,

      id: undefined, // 当前选择的数据 id
      trainingId: undefined, // 培训 id
      classesId: undefined, // 原 class id
      userId: undefined,

      classId: '', // 修改后的 class id
      list: [],
    };
  },
  methods: {
    show({ trainingId, classesId, id, userId } = {}) {
      this.trainingId = trainingId;
      this.id = id;
      this.classesId = classesId;
      this.userId = userId;

      this.shown = true;
      this.getClassList();
    },
    close() {
      this.trainingId = undefined;
      this.classesId = undefined;

      this.classId = '';

      this.shown = false;
    },
    async getClassList() {
      this.loading = true;
      const data = await getAdjustClassList({
        training_id: this.trainingId,
        classes_id: this.classesId,
        user_id: this.userId,
      }).finally(() => {
        this.loading = false;
      });
      this.list = data?.data || data?.info || [];
    },

    async save() {
      if (!this.classId) {
        this.$message.info('请选择班次');
        return;
      }
      if (this.loading) {
        return;
      }
      this.loading = true;

      const data = await saveAdjustClassList({
        old_classes_id: this.classesId,
        new_classes_id: this.classId,
        training_id: this.trainingId,
        apply_id: this.id,
      }).finally(() => {
        this.loading = false;
      });

      if (!data || data.error_code) {
        this.$message.error(data?.message || '更换班次失败');
        return;
      }

      this.$emit('success');
      this.close();
    },
  },
};
</script>

<style scoped lang="scss">
.select {
  min-width: 150px;
}
</style>
