<template>
  <a-modal v-model="visible"
           :title="title"
           :mask-closable="false"
           :width="800"
           :centered="true"
           :confirm-loading="loading">
    <a-table :columns="columns"
             :data-source="data"
             :pagination="false"
             :loading="loading"
             bordered
             row-key="id"
             class="table small-cell-table">
      <template slot="action" slot-scope="text, record">
        <a v-if="record.url" :href="record.url" target="_blank">下载</a>
        <span v-else>计算中</span>
      </template>
    </a-table>

    <template slot="footer">
      <div class="flex flex-end">
        <a-button type="default" @click="close()">关闭</a-button>
      </div>
    </template>
  </a-modal>
</template>

<script>
import moment from 'moment';
import {
  getSettlementRecord,
} from '../api/list';

export default {
  name: 'ExportResultModal',
  data() {
    return {
      loading: false,
      visible: false,

      trainingId: undefined,

      data: [],
      columns: [
        {
          title: '计算日期',
          dataIndex: 'created_at_str',
          key: 'created_at_str',
          align: 'center',
        },
        {
          title: '操作人',
          dataIndex: 'user_name',
          key: 'user_name',
          align: 'center',
        },
        {
          title: '操作',
          key: 'action',
          dataIndex: 'action',
          scopedSlots: { customRender: 'action' },
          align: 'center',
        },
      ],

      title: '导出结果',
    };
  },
  methods: {
    show({ trainingId } = {}) {
      this.trainingId = trainingId;

      this.getData();

      this.visible = true;
    },
    close() {
      this.visible = false;
    },

    async getData() {
      this.loading = true;
      const data = await getSettlementRecord({
        training_id: this.trainingId,
      }).finally(() => {
        this.loading = false;
      });
      if (!data || data.error_code) {
        this.$message.error(data?.message || '获取数据失败');
        return;
      }

      this.data = (data?.data || []).map(
        (i) => ({
          ...i,
          created_at_str: i.created_at ? moment(i.created_at * 1000).format('YYYY-MM-DD HH:mm:ss') : '--',
        }),
      );
    },
  },
};
</script>

<style scoped lang="scss">

</style>
