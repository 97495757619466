<template>
  <router-view/>
</template>

<script>
export default {
  name: 'Index',
  created() {
    this.$store.dispatch('Offline/getOfflineTemplate');
  },
};
</script>

<style scoped lang="scss">

</style>
