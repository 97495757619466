<template>
  <a-modal v-model="visible"
           :destroy-on-close="true"
           :mask-closable="false"
           :confirm-loading="loading"
           :title="title"
           :width="800"
           :centered="true"
           @ok="submit"
           @cancel="close">
    <a-form :form="form" :label-col="{ span: 4 }" :wrapper-col="{ span: 18 }">
      <a-form-item label="考试名称">
        <a-input v-decorator="['title', decorator.title]"
                 placeholder="请输入考试名称"/>
      </a-form-item>
      <a-form-item label="选择试卷">
        <a-select v-decorator="['testpaper_id', decorator.testpaper_id]"
                  placeholder="请选择试卷"
                  :getPopupContainer="(triggerNode) => triggerNode.parentNode">
          <a-select-option v-for="item in testPaperList"
                           :value="item.testpaper_id"
                           :key="item.testpaper_id">
            {{ item.title }}
          </a-select-option>
        </a-select>
      </a-form-item>
      <a-form-item label="对应班次" v-if="trainingType === 1">
        <a-select v-decorator="['classes_id', decorator.classes_id]"
                  placeholder="请选择班次"
                  mode="multiple"
                  :getPopupContainer="(triggerNode) => triggerNode.parentNode">
          <a-select-option v-for="item in classList"
                           :value="item.class_id"
                           :key="item.class_id">
            {{ item.class_name }}
          </a-select-option>
        </a-select>
      </a-form-item>
      <a-form-item label="参考岗位" v-if="trainingType === 2">
        <a-select v-decorator="['postids', decorator.postids]"
                  placeholder="请选择参考岗位"
                  mode="multiple">
          <a-select-option v-for="item in postList"
                           :value="item.post_id"
                           :key="item.post_id">
            {{ item.post_name }}
          </a-select-option>
        </a-select>
      </a-form-item>
      <a-form-item label="考试时长">
        <a-input-number v-decorator="['time', decorator.time]"
                        :min="0"
                        placeholder="请输入考试时长"
                        style="width: 140px;"/>
        <span class="ml-20">分钟</span>
      </a-form-item>
      <a-form-item label="及格分">
        <a-input-number v-decorator="['score', decorator.score]"
                        :min="0"
                        placeholder="请输入及格分"
                        style="width: 140px;"/>
        <span class="ml-20">分</span>
      </a-form-item>
      <a-form-item label="考试次数">
        <a-input-number v-decorator="['number', decorator.number]"
                        :min="0"
                        :formatter="value => `${value}`.replace(/\.*/g, '')"
                        :parser="value => `${value}`.replace(/\.*/g, '')"
                        placeholder="请输入考试次数"
                        style="width: 140px;"/>
        <span class="ml-20">次</span>
      </a-form-item>
      <a-form-item v-if="trainingType === 1" label="考试起止时间">
        <a-range-picker v-decorator="['times', decorator.times]"
                        :show-time="{
                          hideDisabledOptions: false,
                          defaultValue: [startOfDay, endOfDay]
                        }"
                        format="YYYY-MM-DD HH:mm:ss"
                        :getPopupContainer="(triggerNode) => triggerNode.parentNode"/>

      </a-form-item>
      <a-form-item v-if="trainingType === 2" label="选择阶段">
        <a-select v-decorator="['stage', decorator.stage]"
                  placeholder="请选择阶段"
                  @change="stageChange">
          <a-select-option v-for="item in stageList" :key="item.id">
            {{ item.name }}
          </a-select-option>
        </a-select>
      </a-form-item>
      <a-form-item v-if="trainingType === 2" v-show="days && days.length" label=" " :colon="false">
        <a-checkbox-group v-decorator="['show_date', decorator.show_date]">
          <a-checkbox v-for="(item) in days" :key="item" :value="item">
            <span>第{{ item }}天</span>
          </a-checkbox>
        </a-checkbox-group>
      </a-form-item>
      <a-form-item label="查看模式">
        <a-radio-group v-decorator="['is_view']"
                       name="radioGroup">
          <a-radio v-for="item in viewList"
                   :value="item.value"
                   :key="item.value">
            {{ item.label }}
          </a-radio>
        </a-radio-group>
      </a-form-item>
      <a-form-item label="考试规则">
        <a-textarea v-decorator="['rule']"
                    placeholder="请输入考试规则"
                    :allow-clear="true"
                    :rows="8"/>
      </a-form-item>
    </a-form>
  </a-modal>
</template>

<script>
import { mapState } from 'vuex';
import moment from 'moment';

import {
  addExam,
  editExam,
  getExamDetail,
  getClassList,
  getPostList,
  getTestPaperList,
} from '../api/config.exam';

export default {
  name: 'AddExamModal',
  props: {
    trainingId: { type: [Number, String], default: '' },
    trainingType: { type: [Number, String], default: '' },
  },
  computed: {
    ...mapState({
      stageList: (state) => state.Offline.stage,
    }),
    startOfDay() {
      return moment('00:00:00', 'HH:mm:ss');
    },
    endOfDay() {
      return moment('23:59:59', 'HH:mm:ss');
    },
  },
  watch: {
    trainingType: {
      immediate: true,
      handler(type) {
        if (type === 2) {
          this.decorator.stage = {
            rules: [{ required: true, message: '请选择阶段!' }],
          };
          this.decorator.postids = {
            rules: [{ required: true, message: '请选择岗位!' }],
          };
          this.decorator.show_date = {
            rules: [{ required: true, message: '请选择天数' }],
          };
          delete this?.decorator?.classes_id;
          delete this.decorator.times;
        } else {
          this.decorator.classes_id = {
            rules: [{ required: true, message: '请选择对应班次!' }],
          };
          this.decorator.times = {
            rules: [{ required: true, message: '请选择考试起止时间!' }],
          };
          delete this.decorator.stage;
          delete this.decorator.postids;
        }

      },
    },
  },
  data() {
    return {
      loading: false,
      visible: false,
      id: '',
      title: '',

      form: this.$form.createForm(this, {}),
      decorator: {
        title: {
          rules: [{ required: true, message: '请输入考试名称!' }],
        },
        testpaper_id: {
          rules: [{ required: true, message: '请选择试卷!' }],
        },
        time: {
          rules: [{ required: true, message: '请选择考试时长!' }],
        },
        score: {
          rules: [{ required: true, message: '请选择及格分!' }],
        },
        number: {
          rules: [{ required: true, message: '请选择考试次数!' }],
        },
        times: {
          rules: [{ required: true, message: '请选择考试起止时间!' }],
        },
        is_view: {
          rules: [{ required: false, message: '请选择查看模式!' }],
        },
        rule: {
          rules: [{ required: false, message: '请填写考试规则!' }],
        },
      },

      formItemLayout: {
        labelCol: { span: 6 },
        wrapperCol: { span: 18 },
      },

      classList: [], // 班次列表
      postList: [], // 岗位列表
      testPaperList: [], // 试卷列表
      days: [],

      viewList: [
        { value: 0, label: '不可见' },
        { value: 1, label: '自己的答案' },
        { value: 2, label: '自己的答案和正确答案' },
      ],
    };
  },
  created() {
    this.getTestPaperList();
  },
  methods: {
    show({ id } = {}) {
      this.id = id;
      this.title = id ? '编辑考试' : '添加考试';

      this.getClassList();
      this.getPostList();
      this.getData();

      this.visible = true;

      if (!this.id) {
        this.setFields({});
      }
    },
    close() {
      this.id = '';

      this.visible = false;
    },

    async getData() {
      if (!this.id) {
        return;
      }
      this.loading = true;

      const data = await getExamDetail({
        id: this.id,
      }).finally(() => {
        this.loading = false;
      });
      if (!data || data.error_code) {
        this.$message.error(data?.message || '获取考试详情失败');
        return;
      }
      const result = data.data || {};
      this.setFields(result);
    },

    disabledDate(current) {
      return current && current < moment().endOf('day');
    },
    disabledRangeTime(_, type) {
      if (type === 'start') {
        return {
          disabledHours: () => this.range(0, 60).splice(4, 20),
          disabledMinutes: () => this.range(30, 60),
          disabledSeconds: () => [55, 56],
        };
      }
      return {
        disabledHours: () => this.range(0, 60).splice(20, 4),
        disabledMinutes: () => this.range(0, 31),
        disabledSeconds: () => [55, 56],
      };
    },
    range(start, end) {
      const result = [];
      for (let i = start; i < end; i++) {
        result.push(i);
      }
      return result;
    },

    stageChange(evt) {
      const [stage] = this.stageList.filter(
        (i) => i.id === evt,
      ) || [];

      const range = [];

      if (stage?.days) {
        for (let i = 1; i <= stage.days; i += 1) {
          range.push(i);
        }
      }

      this.days = range;
    },


    getFieldDecorator() {
      Object.keys(this.decorator).forEach((key) => {
        this.form.getFieldDecorator(key, this.decorator[key]);
      });
    },
    /**
     * 给表单赋值
     */
    setFields(data) {
      if (!this.visible) {
        return;
      }
      this.getFieldDecorator();
      const fields = {
        ...data,
        time: data?.time || undefined,
        score: data?.score || undefined,
        number: data?.number || 0,
        show_date: data?.show_date?.split(',').map(
          (i) => +i,
        ).filter(
          (i) => !!i,
        ) || [],
        postids: data.postids ? data.postids?.split(',')?.filter(
          (i) => !!i,
        )?.map(
          (i) => +i,
        ) : [],
        classes_id: data.classes_id ? data.classes_id.split(',').filter(
          (i) => !!i,
        )?.map(
          (i) => +i,
        ) || undefined : undefined,
        times: data.start_time && data.end_time ? [
          moment(data.start_time, 'YYYY-MM-DD HH:mm:ss'),
          moment(data.end_time, 'YYYY-MM-DD HH:mm:ss'),
        ] : [undefined, undefined],
        is_view: data?.is_view || 0,
      };

      const parsedFields = {};
      Object.keys(this.decorator).forEach((i) => {
        parsedFields[i] = fields[i];
      });

      this.form.setFieldsValue({ ...parsedFields });

      if (+this.trainingType === 2) {
        this.stageChange(data.stage);
      }
    },

    /**
     * 提交
     */
    submit() {
      this.form.validateFields((err, values) => {
        const result = { ...values };
        if (!err) {
          result.show_date = result.show_date?.join(',') || '';
          result.start_time = result.times?.[0].format('YYYY-MM-DD HH:mm:ss');
          result.end_time = result.times?.[1].format('YYYY-MM-DD HH:mm:ss');
          result.postids = result.postids?.join(',') || '';
          result.classes_id = result.classes_id?.join(',') || '';
          delete result.times;

          if (this.trainingId) {
            result.training_id = this.trainingId;
          }
          if (this.id) {
            result.id = this.id;
          }
          this.save(result);
        }
      });
    },
    async save(params) {
      if (this.loading) {
        return;
      }
      this.loading = true;

      const data = await (params.id ? editExam : addExam)(params).finally(() => {
        this.loading = false;
      });

      if (!data || data.error_code) {
        this.$message.error({ content: data?.message || '保存失败' });
        return;
      }
      this.$message.success(data?.message || '保存成功');

      if (params.id) {
        this.$emit('edited');
      } else {
        this.$emit('created');
      }
      this.close();
    },

    /**
     * 获取班次列表
     * @returns {Promise<void>}
     */
    async getClassList() {
      if (+this.trainingType !== 1) {
        return;
      }
      const data = await getClassList({
        training_id: this.trainingId,
      });
      this.classList = data?.data || [];
    },
    /**
     * 获取参考岗位
     * @returns {Promise<void>}
     */
    async getPostList() {
      if (+this.trainingType !== 2) {
        return;
      }
      const data = await getPostList({
        training_id: this.trainingId,
      });
      this.postList = data?.data || [];
    },
    /**
     * 获取试卷
     * @returns {Promise<void>}
     */
    async getTestPaperList() {
      const data = await getTestPaperList();
      this.testPaperList = data?.data || [];
    },
  },
};
</script>

<style scoped lang="scss">
.w400 {
  width: 400px;
}
</style>
