<template>
  <div class="main">
    <a-form
      class="ant-advanced-search-form"
      :form="form"
      @submit="handleSubmit"
      v-bind="formItemLayout"
    >
      <a-form-item label="板块1标题">
        <a-input
          v-decorator="['title_block_1', validatorRules.title_block_1]"
          placeholder="请输入"
          class="w700"
        />
      </a-form-item>
      <a-form-item>
        <template #label>
          <span class="bt-bt">*</span>
          <a-input
            placeholder="课程描述"
            v-model="block_1_key"
            style="width: 100px; display: inline-block"
          />
        </template>
        <a-textarea
          v-model="block_1_value"
          placeholder="请输入"
          class="w700"
          allow-clear
        />
      </a-form-item>
      <a-form-item label="图片">
        <a-button type="primary" @click="addPic" ghost> 添加图片组</a-button>
        <template v-for="(item, index) in addPicList">
          <upLoadPicAndTitle
            :key="item.uid"
            :ref="'picList' + index"
            class="w700"
            :index="index"
            :title="item.title"
            :file-list="item.fileList"
            @uploadChange="saveImgList"
            @deletePic="deletePic"
            @uploadLoainng="onUploadLoainng"
          ></upLoadPicAndTitle>
        </template>
      </a-form-item>
      <a-form-item label="板块2标题">
        <a-input
          v-decorator="['title_block_2', validatorRules.title_block_2]"
          placeholder="请输入"
          class="w700"
        />
      </a-form-item>
      <a-form-item label="报名说明">
        <a-textarea
          v-decorator="['enroll_desc', validatorRules.enroll_desc]"
          placeholder="请输入"
          class="w700"
          allow-clear
        />
      </a-form-item>
      <a-form-item label="时间">
        <a-range-picker @change="onChange" v-model="defaultValue"/>
      </a-form-item>
      <a-form-item label="地点">
        <a-input
          v-decorator="['address', validatorRules.address]"
          placeholder="请输入"
          class="w700"
        />
      </a-form-item>
      <a-form-item label="报名岗位">
        <selectJob
          v-decorator="['post_ids', validatorRules.post_ids]"
          :list="posts"
          @change="changeTsgw"
          class="w700"
        ></selectJob>
      </a-form-item>
      <a-form-item label="自定义字段">
        <a-button type="primary" @click="addCustomColumn" ghost
        >添加字段
        </a-button
        >
        <div class="flex-box pt-10" v-for="(item, i) in customColumn" :key="i">
          <a-input
            v-model.trim="item.title"
            placeholder="请输入字段名"
            :maxLength="4"
            class="w120 mr10"
          />
          <a-input
            v-model.trim="item.contents"
            placeholder="请输入字段值"
            class="w200 mr10"
          />
          <a-icon class="close" type="close" @click="delCustomColumn(i)"/>
        </div>
      </a-form-item>
      <a-form-item label="报名讲解视频">
        <div class="upload">
          <Uploader
            v-model="enrollVideoIds"
            accept="video/*"
            :count="1"
            :custom-file-list="enrollVideos"
            @uploadLoainng="onUploadLoainng"
          >
            <a-button>
              <a-icon type="upload"/>

              <span v-if="enrollVideoIds">视频已上传</span>
              <span v-else>添加视频</span>
            </a-button>
          </Uploader>
        </div>
      </a-form-item>
      <a-form-item label="是否必看" v-if="enrollVideoIds">
        <a-radio-group name="radioGroup" v-model="is_must_completed">
          <a-radio :value="1"> 是</a-radio>
          <a-radio :value="2"> 否</a-radio>
        </a-radio-group>
      </a-form-item>
      <a-form-item label="完成节点">
        <a-input-number v-model="completion_node" :min="1" :max="100"/>
        %
      </a-form-item>
      <a-form-item label="咨询人">
        <a-input
          class="w700"
          placeholder="请输入"
          v-decorator="['contract', validatorRules.contract]"
        />
      </a-form-item>
      <a-form-item label="咨询电话">
        <a-input
          class="w700"
          placeholder="请输入"
          v-decorator="['contract_phone', validatorRules.contract_phone]"
        />
      </a-form-item>
    </a-form>
    <saveButtons
      ref="saveButtons"
      @handleSubmit="handleSubmit"
      @saveAndNext="saveAndNext"
      :loading="loading"
    ></saveButtons>
  </div>
</template>

<script>
import { getInfoPage, infoPageEdit } from '@/api/config';
import pick from 'lodash.pick';
import { ConfigMixin } from './mixins/configMixins';
import moment from 'moment';
import { getTrainingPosts } from './api/api';

import Uploader from '@/components/upload/Uploader';

export default {
  name: 'basicConfiguration',
  mixins: [ConfigMixin],
  components: {
    Uploader,
    upLoadPicAndTitle: () => import('./components/upLoadPicAndTitle'),
    saveButtons: () => import('./components/saveButtons'),
    selectJob: () => import('@/components/select/SelectJob'),
  },
  data() {
    return {
      formItemLayout: {
        labelCol: { span: 4 },
        wrapperCol: { span: 20 },
      },
      form: this.$form.createForm(this),
      selectList4: [
        {
          label: '岗位类别',
          value: '1',
        },
        {
          label: '岗位名称',
          value: '2',
        },
      ],
      inputVisible: false,
      inputValue: '',
      addPicList: [],
      validatorRules: {
        title_block_1: {
          rules: [{ required: true, message: '请输入板块1标题!' }],
        },
        lesson_desc: {
          rules: [{ required: true, message: '请输入课程描述!' }],
        },
        title_block_2: {
          rules: [{ required: true, message: '请输入板块2标题!' }],
        },
        post_ids: {
          rules: [{ required: true, message: '请选择报名岗位!' }],
        },
      },
      customColumn: [],
      times: [],
      imgList: [],
      enrollVideoIds: '',
      enrollVideos: [],
      accept: 'video/mp4',
      editId: null,
      defaultValue: [undefined, undefined],
      is_must_completed: 2,
      posts: [],
      completion_node: 90,
      block_1_key: '课程描述',
      block_1_value: '',
      loading: false,

      uid: 1, // 图片组用来区分的自增 id
    };
  },
  created() {
    this.getPosts();
    this.getData();
  },
  watch: {
    enrollVideoIds(value) {
      if (value.length > 1) {
        this.enrollVideos = [this.enrollVideos[1]];
      }
    },
  },
  methods: {
    async getPosts() {
      const data = await getTrainingPosts({
        training_id: this.$route.query.id,
      });
      this.posts = data?.data;
    },
    async getData() {
      this.loading = true;
      const data = await getInfoPage(this.$route.query.id).finally(
        () => (this.loading = false),
      );
      if (!data || data.error_code) {
        return;
      }
      if (data || data?.data?.length > 0) {
        let datas = data.data;
        this.enrollVideoIds = datas.enroll_videos || '';
        this.customColumn = datas.custom_columns;
        this.block_1_value = datas.block_1_value;
        this.block_1_key = datas.block_1_key;
        this.addPicList =
          datas?.images?.map((s, index) => {
            return {
              title: s.title,
              fileList: s.resource_files,
              index: index,
              uid: this.uid++,
            };
          }) || [];
        this.imgList =
          datas?.images?.map((s) => {
            return {
              title: s.title,
              path: s.path?.split(',') ?? [],
              uid: this.uid++,
            };
          }) ?? [];
        this.$nextTick(() => {
          this.form.setFieldsValue({
            ...datas,
            lesson_desc: datas.block_1_value,
          });
          this.editId = data?.data?.id || null;
        });
        if (datas.start_time_fmt && datas.end_time_fmt) {
          this.defaultValue = [
            moment(datas.start_time_fmt),
            moment(datas.end_time_fmt),
          ];
        }
        this.completion_node = datas?.completion_node ?? 90;
        this.is_must_completed =
          datas.is_must_completed > 0 ? datas.is_must_completed : 2;
      } else {
        this.setDefaultData();
      }
    },
    addCustomColumn() {
      let obj = {
        title: '',
        contents: '',
      };
      this.customColumn = this.customColumn || [];
      this.customColumn.push(obj);
    },
    handleSubmit() {
      let _this = this;
      _this.form.validateFields((err, values) => {
        //let num = _this.addPicList?.length;
        if (!err) {
          if (values.is_must_completed === 1 && !this.completion_node) {
            _this.$message.error('请输入完成节点!');
            _this.imgList = [];
            return;
          }
          values.post_ids = values.post_ids + '';
          values.custom_column = JSON.stringify(_this.customColumn);
          values.enroll_videos = _this.enrollVideoIds;
          values.images = JSON.stringify(_this.imgList);
          _this.editId
            ? (values.id = _this.editId)
            : (values.training_id = _this.$route.query.id);
          _this.times.length &&
          ((values.start_time = _this.times[0]),
            (values.end_time = _this.times[1]));
          if (
            _this.times.length === 0 &&
            this.defaultValue[0] &&
            this.defaultValue[1]
          ) {
            values.start_time = this.defaultValue[0].format('YYYY-MM-DD');
            values.end_time = this.defaultValue[1].format('YYYY-MM-DD');
          }
          values.completion_node = this?.completion_node ?? 90;
          values.is_must_completed = this.is_must_completed;
          values.block_1_key = this.block_1_key;
          values.block_1_value = values?.lesson_desc ?? this.block_1_value;
          this.lesson_desc = values.lesson_desc;
          if (!(values.block_1_key || values.block_1_value)) {
            this.$message.error('课程描述为必填项');
            return;
          }
          values.images = JSON.stringify(
            this.imgList
            ?.filter((s) => s?.path?.length ?? 0 > 0)
            ?.map((s) => {
              return {
                title: s.title,
                path: s.path,
              };
            }) ?? [],
          );
          _this.save(values);
        }
      });
    },
    async save(params) {
      this.loading = true;
      const data = await infoPageEdit(params).finally(
        () => (this.loading = false),
      );

      if (!data || data.error_code) {
        this.$message.error({ content: data?.message || '保存失败' });
        return;
      }
      this.$message.success({ content: data?.message || '保存成功' });
      this.goNextStep('previewContentsConfiguration');
    },
    handleChange() {
    },
    onSwitchChange() {
    },
    onChange(date, dateString) {
      this.times = dateString;
    },
    handleClose(removedTag) {
      this.tags = this.tags.filter((tag) => tag !== removedTag);
    },
    addPic() {
      this.addPicList.push({
        title: '',
        fileList: [],
        index: this.addPicList.length,
        uid: this.uid++,
      });
      this.imgList.push({});
    },
    delCustomColumn(i) {
      this.customColumn.splice(i, 1);
    },
    setDefaultData() {
      this.$nextTick(() => {
        this.form.setFieldsValue(
          pick(
            'title_block_1',
            'lesson_desc',
            'title_block_2',
            'enroll_desc',
            'address',
            'post_ids',
            'completion_node',
            'contract',
            'contract_phone',
          ),
        );
        this.form.setFieldsValue({ completion_node: 90 });
      });
      this.form.setFieldsValue({ is_must_completed: 1 });
    },
    saveImgList(data) {
      this.imgList[data.index] = {
        path: data?.path,
        title: data.title,
      };
    },
    changeTsgw(v) {
      this.form.setFieldsValue({ post_ids: v });
    },
    deletePic(data) {
      this.addPicList.splice(data.index, 1);
      this.imgList.splice(data.index, 1);
      // this.fileList = this?.fileList?.filter((s, idx) => {
      //  return idx !==data.index
      // }) ?? [];
      // this.imgList = this?.imgList?.filter((s, idx) => {
      //  return idx !==data.index
      // }) ?? [];
    },
    onUploadLoainng(value) {
      this.loading = value;
    },
  },
};
</script>

<style lang="less" scoped>
.flex-box {
  display: flex;
}

.wp100 {
  width: 100%;
}

.bg-grey {
  background-color: rgb(242, 242, 242);
  padding: 0 15px;

  .rt {
    flex: 1;
  }

  .lt {
    padding-right: 10px;
  }
}

.text-center {
  text-align: center;
}

.w700 {
  width: 700px;
}

.w200 {
  width: 200px;
}

.w120 {
  width: 120px;
}

.mr10 {
  margin-right: 10px;
}

.close {
  width: 34px;
  height: 34px;
  line-height: 34px;
  cursor: pointer;
}

.close:hover {
  color: #048cdb;
}

.upload {
  width: 600px;
}

::v-deep editor-squiggler {
  display: none;
}

.bt-bt {
  display: inline-block;
  margin-right: 4px;
  color: #f5222d;
  font-size: 14px;
  font-family: SimSun, sans-serif;
  line-height: 1;
}
</style>
