<template>
  <div ref="selectionRef"
       class="select ant-select ant-select-selection"
       :class="{'focus': focused, 'focus-in': focusState === 1, 'focus-out': focusState === 2}"
       @click.stop="toggleFocus">
    <div v-if="selectedItem && selectedItem.id">{{ selectedItem.title }}</div>
    <div v-else class="placeholder">请选择人员表</div>

    <div v-if="focused" class="options"
         :class="{'options-in': focusState === 1, 'options-out': focusState === 2}">
      <div v-for="item in options" :key="item.id" class="option"
           :class="{'active': selectedItem.id === item.id, 'hover': hoveredItem.id === item.id}"
           @mouseenter="hoverItem(item)"
           @click.stop="selectItem(item)">
        <span class="msg">{{ item.title }}</span>
        <div v-if="allowDelete" class="remove-item" @click.stop="removeItem(item)">
          <a-icon type="close"/>
        </div>
      </div>
      <div v-if="!options || !options.length" class="empty">列表为空</div>
    </div>

    <div class="drop-icon"></div>
  </div>
</template>

<script>
export default {
  name: 'SelectionWidthDelete',
  model: {
    prop: 'id',
    event: 'select',
  },
  props: {
    options: { type: Array, default: () => [] },
    id: { type: Number },
    allowDelete: { type: Boolean, default: false },
  },
  watch: {
    id: {
      handler(newValue) {
        if (this.options?.length) {
          const [option] = this.options.filter(i => i.id === newValue);
          this.selectedItem = option || {};
          this.hoveredItem = this.selectedItem;
        }
      },
      immediate: true,
    },
  },
  data() {
    return {
      focused: false,
      focusState: 0, // 0 啥也不是；1：in；2：out；
      selectedItem: {},
      hoveredItem: {},
      stop: false,
    };
  },
  mounted() {
    document.addEventListener('click', this.removeFocus, false);
  },
  beforeDestroy() {
    document.removeEventListener('click', this.removeFocus);
  },
  methods: {
    toggleFocus() {
      if (this.focused) {
        this.removeFocus();
      } else {
        this.setFocus();
      }
    },
    setFocus() {
      this.focused = true;
      this.hoveredItem = this.selectedItem;
      if (this.focusState !== 1) {
        this.focusState = 1;
      }
    },
    removeFocus() {
      this.focused = false;

      if (this.focusState !== 2) {
        this.focusState = 2;
      }
    },

    hoverItem(item) {
      if (!item?.id) {
        return;
      }

      this.hoveredItem = item;
    },
    selectItem(item) {
      if (!item?.id) {
        return;
      }

      this.selectedItem = item;
      this.removeFocus();
      this.$emit('select', item.id);
      this.$emit('select-origin', item);
    },

    removeItem(item) {
      if (!item?.id || !this.allowDelete) {
        return;
      }

      this.$confirm({
        content: () => <div>确认删除？</div>,
        onOk: () => {
          if (!item?.id) {
            return;
          }
          this.$emit('delete-item', item);
        },
      });
    },
  },
};
</script>

<style scoped lang="scss">
.select {
  position: relative;
  min-width: 280px;
  height: 32px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 11px;

  &:hover {
    cursor: pointer;
  }

  &.focus-in .drop-icon {
    animation: drop-icon-up 0.3s;
    transform: translateY(3px) rotate(135deg) skew(-7deg, -7deg);
  }

  &.focus-out .drop-icon {
    animation: drop-icon-down 0.3s;
    transform: translateY(-2px) rotate(-45deg) skew(-7deg, -7deg);
  }
}

.placeholder {
  color: #BFBFBF;
}

.drop-icon {
  $size: 8px;
  flex: none;
  width: $size;
  height: $size;
  margin-left: 5px;
  border-left: 1px solid #BFBFBF;
  border-bottom: 1px solid #BFBFBF;
  transform: translateY(-2px) rotate(-45deg) skew(-7deg, -7deg);
}

.options {
  overflow-y: auto;
  position: absolute;
  top: 34px;
  left: 0;
  right: 0;
  z-index: 199;
  max-height: 250px;
  padding: 3px 0;
  border-radius: 4px;
  background-color: #FFFFFF;
  box-shadow: 0 2px 8px rgb(0 0 0 / 15%);

  &.options-in {
    animation: options-in 0.1s;
  }

  &.options-out {
    animation: options-out 0.1s;
  }
}

.option {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 32px;
  padding: 0 11px;

  &.hover {
    background-color: #E6F7FF;

    .remove-item {
      display: flex;
    }
  }

  &.active {
    font-weight: 700;
  }

  .msg {
    flex: auto;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  .remove-item {
    flex: none;
    display: none;
    justify-content: flex-end;
    align-items: center;
    width: 20px;
    height: 20px;
  }
}

@keyframes drop-icon-up {
  from {
    transform: translateY(-2px) rotate(-45deg) skew(-7deg, -7deg);
  }
  to {
    transform: translateY(3px) rotate(135deg) skew(-7deg, -7deg);
  }
}

@keyframes drop-icon-down {
  from {
    transform: translateY(3px) rotate(135deg) skew(-7deg, -7deg);
  }
  to {
    transform: translateY(-2px) rotate(-45deg) skew(-7deg, -7deg);
  }
}

@keyframes options-in {
  0% {
    transform: scaleY(0) translateY(-100%);
  }
  90% {
    transform: scaleY(1) translateY(0);
  }
  100% {
    transform: scaleY(1);
  }
}

@keyframes options-out {
  0% {
    transform: scaleY(1);
  }
  90% {
    transform: scaleY(0);
  }
  100% {
    transform: scaleY(0);
  }
}
</style>
