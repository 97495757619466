import store from '@/store/index';
import request from '@/utils/Request';
import { downloadFileExtra } from '@/utils/download/index';

// 打包线下培训数据
export function zipFiles(id) {
  return request({
    url: `/offline/admin/zip/createZip/${id}`,
    method: 'POST',
  });
}

// 获取下载文本地址
export function getZippedFilesUrl(id) {
  return request({
    url: `/offline/admin/zip/getZipDownAddr/${id}`,
    method: 'GET',
  });
}

// 获取下载文本地址 - 下载文件
export function zippedFilesUrlDownload(path) {
  return downloadFileExtra({
    url: path,
    method: 'GET',
    token: store.state.User.token || '',
  });
}
