<template>
  <div class="main">
    <a-form
      class="ant-advanced-search-form"
      :form="form"
      @submit="handleSubmit"
      v-bind="formItemLayout"
    >
      <a-form-item v-if="trainingType === 1" label="预报名时间">
        <a-range-picker
          v-model="ybmTime"
          @change="onChangeY"
          :disabled-date="disabledDate"
        />
      </a-form-item>
      <template v-if="trainingType === 1">
        <a-form-item v-if="ybmTime && ybmTime.length" label="预报名表单">
          <div class="flex-box">
            <a-select
              placeholder="请选择"
              style="width: 250px"
              v-decorator="[
                'forecast_form_id',
                validatorRules.forecast_form_id
              ]"
              @change="onformId"
            >
              <a-select-option
                v-for="item in selectSignUpList"
                :value="item.id"
                :key="item.id"
              >
                {{ item.title }}
              </a-select-option>
            </a-select>
            <a-button
              type="primary"
              class="ml-10"
              ghost
              @click="openLayer(1)"
            >编辑表单
            </a-button>
          </div>
        </a-form-item>
        <a-form-item v-else label="预报名表单">
          <div class="flex-box">
            <a-select
              placeholder="请选择"
              style="width: 250px"
              v-model="forecast_form_id"
            >
              <a-select-option
                v-for="item in selectSignUpList"
                :value="item.id"
                :key="item.id"
              >
                {{ item.title }}
              </a-select-option>
            </a-select>
            <a-button type="primary" class="ml-10" ghost @click="openLayer(1)"
            >编辑表单
            </a-button>
          </div>
        </a-form-item>
      </template>
      <a-form-item label="正式报名时间">
        <a-range-picker
          v-decorator="['zsbmTime', validatorRules.zsbmTime]"
          @change="onChangeZ"
          :disabled-date="disabledDate"
        />
      </a-form-item>
      <a-form-item label="正式报名表单">
        <div class="flex-box">
          <a-select
            placeholder="请选择"
            style="width: 250px"
            v-decorator="['formal_form_id', validatorRules.formal_form_id]"
          >
            <a-select-option
              v-for="item in selectSignUpList"
              :value="item.id"
              :key="item.id"
            >
              {{ item.title }}
            </a-select-option>
          </a-select>
          <a-button type="primary" class="ml-10" ghost @click="openLayer(2)"
          >编辑表单
          </a-button>
        </div>
      </a-form-item>
      <a-form-item v-if="trainingType === 2" label="报名审批">
        <a-checkbox-group v-model="bmsp">
          <a-row>
            <a-col :span="8" v-for="item in selectLists" :key="item.value">
              <a-checkbox :value="item.value">
                {{ item.label }}
              </a-checkbox>
            </a-col>
          </a-row>
        </a-checkbox-group>
      </a-form-item>
    </a-form>
    <saveButtons
      ref="saveButtons"
      @handleSubmit="handleSubmit"
      @saveAndNext="saveAndNext"
    ></saveButtons>
    <editSignUpFormDetailLayer
      ref="layer"
      :id="formId"
      @success="getSelectList"
    ></editSignUpFormDetailLayer>
  </div>
</template>

<script>
import { signUpEdit, getSignUpSelectList, getSignUp } from '@/api/config';
import pick from 'lodash.pick';
import { ConfigMixin } from './mixins/configMixins';
import { Row, Col } from 'ant-design-vue';
import moment from 'moment';
import { mapState } from 'vuex';

export default {
  name: 'signUpConfiguration',
  mixins: [ConfigMixin],
  components: {
    'a-row': Row,
    'a-col': Col,
    saveButtons: () => import('./components/saveButtons'),
    editSignUpFormDetailLayer: () =>
      import('./components/editSignUpFormDetailLayer'),
  },
  computed: {
    ...mapState({
      trainingType: (state) => state.Offline.trainType,
    }),
  },
  watch: {
    ybmTime: {
      immediate: true,
      deep: true,
      handler(val) {
        if (val?.length) {
          if (!this.validatorRules.forecast_form_id) {
            this.validatorRules = {
              ...this.validatorRules,
              forecast_form_id: {
                rules: [{ required: true, message: '请选择预报名表单!' }],
              },
            };
          }
        } else {
          this.validatorRules.forecast_form_id = null;
        }
      },
    },
  },
  data() {
    return {
      formItemLayout: {
        labelCol: { span: 4 },
        wrapperCol: { span: 20 },
      },
      form: this.$form.createForm(this),
      ybmTime: [],
      selectLists: [
        {
          label: '本部审批',
          value: '1',
        },
        {
          label: '大区审批',
          value: '2',
        },
        {
          label: '小区审批',
          value: '3',
        },
        {
          label: '经销商审批',
          value: '4',
        },
      ],
      selectSignUpList: [],
      ybmForm: '',
      zsbmForm: '',
      forecast_form_id: undefined,
      bmsp: [],
      validatorRules: {
        description: {
          rules: [{ required: true, message: '报名说明!' }],
        },
        formal_form_id: {
          rules: [{ required: true, message: '请选择正式报名表单!' }],
        },
        zsbmTime: {
          rules: [{ required: true, message: '请选择报名时间!' }],
        },
      },
      nowId: null,
      formId: null,
      enrollId: 0,
      post_ids: '',
    };
  },
  async created() {
    await this.getData();
    await this.getSelectList(this.enrollId);
  },
  methods: {
    disabledDate(current) {
      return current.isBefore(moment(), 'days');
    },
    async getData() {
      this.loading = true;
      const data = await getSignUp(this.id).finally(
        () => (this.loading = false),
      );
      if (!data || data.error_code) {
        return;
      }
      if (data) {
        let datas = data.data;
        this.post_ids = datas.post_ids;
        this.forecast_form_id = datas?.forecast_form_id || undefined;
        datas.forecast_start_time &&
        datas.forecast_end_time &&
        (this.ybmTime = [
          moment(datas.forecast_start_time * 1000),
          moment(datas.forecast_end_time * 1000),
        ]);
        datas.formal_start_time &&
        datas.formal_end_time &&
        (datas.zsbmTime = [
          moment(datas.formal_start_time * 1000),
          moment(datas.formal_end_time * 1000),
        ]);
        this.$nextTick(() => {
          this.form.setFieldsValue(datas);
          this.nowId = datas.id;
          this.bmsp = [];
          datas.is_headquarters && this.bmsp.push('1');
          datas.is_region && this.bmsp.push('2');
          datas.is_village && this.bmsp.push('3');
          datas.is_agent && this.bmsp.push('4');
          this.formId = datas.formal_form_id;
        });
        this.enrollId = datas.id;
      } else {
        this.setDefaultData();
      }
    },
    async getSelectList(id) {
      this.loading = true;
      if (this.$refs.layer?.visible) {
        this.$refs.layer.visible = false;
      }

      const data = await getSignUpSelectList({
        id: this.id,
        enroll_id: id,
      }).finally(() => (this.loading = false));
      if (!data || data.error_code) {
        return;
      }
      if (data) {
        this.selectSignUpList = data.data;
      } else {
        this.setDefaultData();
      }
    },
    setDefaultData() {
      this.$nextTick(() => {
        this.form.setFieldsValue(pick('description'));
      });
      this.form.setFieldsValue({ is_must_completed: 1 });
    },
    handleSubmit() {
      this.form.validateFields((err, values) => {
        if (!err) {
          values.forecast_start_time = this.ybmTime[0]?.format('YYYY-MM-DD');
          values.forecast_end_time = this.ybmTime[1]?.format('YYYY-MM-DD');
          values.formal_start_time = values.zsbmTime[0]?.format('YYYY-MM-DD');
          values.formal_end_time = values.zsbmTime[1]?.format('YYYY-MM-DD');

          let str = this.bmsp + '';
          values.is_headquarters = str.indexOf('1') > -1 ? 1 : 0;
          values.is_region = str.indexOf('2') > -1 ? 1 : 0;
          values.is_village = str.indexOf('3') > -1 ? 1 : 0;
          values.is_agent = str.indexOf('4') > -1 ? 1 : 0;
          if (this.forecast_form_id) {
            values.forecast_form_id = this.forecast_form_id;
          }
          values.post_ids = this.post_ids;
          values.training_id = this.id;
          values.id = this.nowId;
          this.save(values);
        }
      });
    },
    async save(params) {
      this.loading = true;
      const data = await signUpEdit(params).finally(
        () => (this.loading = false),
      );

      if (!data || data.error_code) {
        this.$message.error({ content: data?.message || '保存失败' });
        return;
      }
      this.nowId = data?.data?.id || this.nowId;
      this.$message.success({ content: data?.message || '保存成功' });
      if (this.trainingType === 1) {
        this.goNextStep('classConfiguration');
      } else {
        this.goNextStep('instructorAppointment');
      }
    },
    onChangeY(date) {
      const forecastEndTime = date?.[1];
      const formalStartTime = this.form.getFieldValue('zsbmTime')?.[0];

      if (forecastEndTime && formalStartTime && !forecastEndTime?.isBefore(formalStartTime)) {
        this.$message.error(
          '预报名时间不能大于正式报名时间,请重新选择预报名时间',
        );
        this.ybmTime = [undefined, undefined];
      }
    },

    onChangeZ(date) {
      const forecastTime = this.ybmTime;
      const forecastEndTime = forecastTime?.[1];
      const formalStartTime = date[0];

      if (forecastEndTime && !forecastEndTime?.isBefore(formalStartTime, 'days')) {
        this.$message.error('正式报名不能小于预报名时间');
        setTimeout(() => {
          this.form.setFieldsValue({
            zsbmTime: [
              forecastEndTime.clone().add(1, 'days'),
              forecastEndTime.clone().add(2, 'days'),
            ],
          });
        });
      }
    },
    openLayer(type) {
      let str = type === 1 ? 'forecast_form_id' : 'formal_form_id';
      let msg = type === 1 ? '请选择预报名表单！' : '请选择正式报名表单！';
      let id = this.form.getFieldsValue()[str];

      if (!id && !this.forecast_form_id) {
        this.$message.info(msg);
        return;
      }
      this.$router.push({
        path: '/index/offline/form/detail',
        query: {
          id: id || this.forecast_form_id,
          type: 'edit',
        },
      });
    },
    onformId(value) {
      this.forecast_form_id = value;
    },
  },
};
</script>

<style lang="less" scoped>
.flex-box {
  display: flex;
}

.wp100 {
  width: 100%;
}

.bg-grey {
  background-color: rgb(242, 242, 242);
  padding: 0 15px;

  .rt {
    flex: 1;
  }

  .lt {
    padding-right: 10px;
  }
}

.text-center {
  text-align: center;
}

.w700 {
  width: 700px;
}

.w200 {
  width: 200px;
}
</style>
