<template>
  <a-tree-select v-model="ids"
                 :tree-data="trainingCategoryList"
                 :replace-fields="{
                    title: 'title',
                    key: 'id',
                    value: 'id',
                    children: 'childs',
                 }"
                 :allow-clear="true"
                 :tree-checkable="true"
                 :tree-default-expand-all="true"
                 :max-tag-count="1"
                 :max-tag-placeholder="(values) => `共${values.length + 1}项`"
                 placeholder="请选择业务分类"
                 class="select-training-category"
                 @change="onChange">
    <a-icon type="down" slot="suffixIcon"/>
  </a-tree-select>
</template>

<script>
import { getCategoriesSelectList } from '../api/api';

export default {
  name: 'SelectTrainingCategories',
  model: {
    prop: 'value',
    event: 'change',
  },
  props: {
    value: { type: [Number, String], default: '' },
  },
  watch: {
    value: {
      immediate: true,
      handler(val) {
        this.ids = val ? val.split(',') : undefined;
      },
    },
  },
  data() {
    return {
      ids: [],
      trainingCategoryList: [],
    };
  },
  created() {
    this.getCategoriesSelectList();
  },
  methods: {
    async getCategoriesSelectList() {
      const data = await getCategoriesSelectList();
      this.trainingCategoryList = data?.data || [];
    },
    onChange() {
      this.$emit('change', this.ids?.join(',') || '');
    },
  },
};
</script>

<style lang="scss" scoped>
.select-training-category {
  min-width: 100px;
  width: 230px;
  max-height: 30px;

  ::v-deep .ant-select-selection {
    width: 230px;
    height: 30px;
    max-height: 32px;

    .ant-select-selection__rendered {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: flex-start;
      align-items: center;

      .ant-select-selection__choice {
        flex-grow: 1;
        flex-shrink: 0;

        &:first-child {
          flex-shrink: 1;
        }
      }
    }
  }
}
</style>
