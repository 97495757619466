import request from '@/utils/Request';

//业务列表
export function getTrainList(params) {
  return request({
    url: '/offline/admin/training',
    method: 'get',
    params,
  });
}

// 业务看板-按业务-培训
export function getDashboardTrain(params) {
  return request({
    url: '/offline/admin/dashboard/index_by_training',
    method: 'get',
    params,
  });
}

// 业务看板-按业务-辅导
export function getDashboardTutor(params) {
  return request({
    url: '/offline/admin/dashboard/index_by_tutoring',
    method: 'get',
    params,
  });
}

// 业务看板-按经销商
export function getDashboardOrg(params) {
  return request({
    url: '/offline/admin/dashboard/index_by_org',
    method: 'get',
    params,
  });
}

// 业务看板-获取审批人信息
export function getApprover(params) {
  return request({
    url: '/offline/admin/dashboard/next_approver',
    method: 'get',
    params,
  });
}

// 业务看板-获取更改原时间
export function getChangeDate(params) {
  return request({
    url: '/offline/admin/dashboard/get_change_date',
    method: 'get',
    params,
  });
}

// 业务看板-设置取消小红点
export function setConfirm(params) {
  return request({
    url: '/offline/admin/dashboard/set_confirm',
    method: 'get',
    params,
  });
}

// 页面信息配置-获取业务分类下拉列表
export function getCategoriesSelectList(params) {
  return request({
    url: '/offline/admin/training/categories/select-list',
    method: 'get',
    params,
  });
}

// 页面信息配置-获取业务分类下拉列表
export function getOrgList(params) {
  return request({
    url: '/offline/admin/dashboard/org_list',
    method: 'get',
    params,
  });
}
