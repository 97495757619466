<template>
  <a-modal v-model="visible" :title="title"
           :mask-closable="false" @ok="handleSubmit" :width="700">
    <a-form class="ant-advanced-search-form" :form="form" @submit="handleSubmit" v-bind="formItemLayout">
      <a-form-item label="选择讲师">
        <a-select placeholder="请选择" class="w700" v-decorator="['teacher_id', validatorRules.teacher_id]">
          <a-select-option v-for="item in selectLecturerList" :value="item.id" :key="item.id">
            {{ item.name }}
          </a-select-option>
        </a-select>
      </a-form-item>
      <a-form-item label="讲师介绍">
        <a-textarea placeholder="请输入" v-decorator="['teacher_desc', validatorRules.teacher_desc]" class="w700"
                    allow-clear/>
      </a-form-item>
      <a-form-item label="封面图">
        <uploadFile @success="uploadImgSuccess" :isImgUpload="true"></uploadFile>
      </a-form-item>
      <a-form-item label="试讲视频">
        <uploadFile text="选择本地视频" @success="uploadVideoSuccess" :accept="accept"></uploadFile>
      </a-form-item>
      <a-form-item label="展示课件">
        <uploadFile text="选择本地文件" @success="uploadFileSuccess" :infinity="true"></uploadFile>
      </a-form-item>
      <a-form-item label="可选经销商">
        <a-select placeholder="请选择" class="w700" v-model="jxs">
          <a-select-option v-for="item in selectList" :value="item.value" :key="item.value">
            {{ item.label }}
          </a-select-option>
        </a-select>
      </a-form-item>
    </a-form>
  </a-modal>
</template>

<script>
import { getLecturerSelectList, addTeacher, editTeacher, getTeacherDetail } from '@/api/config';
import pick from 'lodash.pick';

export default {
  name: 'addLecturerLayer',
  components: {
    uploadFile: () => import('@/components/upload/uploadFile'),
  },
  data() {
    return {
      visible: false,
      form: this.$form.createForm(this),
      selectList: [{
        label: '大区',
        value: '1',
      }, {
        label: '小区',
        value: '2',
      }, {
        label: '经销商',
        value: '3',
      }],
      formItemLayout: {
        labelCol: { span: 4 },
        wrapperCol: { span: 20 },
      },
      validatorRules: {
        teacher_id: {
          rules: [
            { required: true, message: '请选择讲师!' },
          ],
        },
        teacher_desc: {
          rules: [
            { required: true, message: '请输入讲师介绍!' },
          ],
        },
      },
      selectLecturerList: [],
      accept: 'video/mp4',
      id: null,
      jxs: '',
      title: '',
      jjsp: '',
      zskj: [],
      fmt: '',
      addTeacher, editTeacher,
      isEdit: false,
    };
  },
  created() {
    this.getDomainSelectList();
  },
  methods: {
    openMe(data) {
      (data && data.id) ? (this.getData(data.id), this.id = data.id, this.title = '编辑讲师', this.isEdit = true) : (this.setDefaultData(), this.title = '添加讲师', this.isEdit = false);
      this.visible = true;
    },
    async getData(id) {
      this.loading = true;
      const data = await getTeacherDetail(id).finally(() => this.loading = false);
      if (!data || data.error_code) {
        return;
      }
      if (data) {
        this.$nextTick(() => {
          let datas = data.data;
          this.form.setFieldsValue(pick(datas, 'teacher_id', 'teacher_desc', 'test_video', 'courseware', 'cover_img', 'org_ids'));
          this.jxs = datas.org_ids ? datas.org_ids : '';
        });
      } else {
        this.$message.error(data.message);
      }
    },
    setDefaultData() {
      this.$nextTick(() => {
        this.form.setFieldsValue(pick('teacher_id', 'teacher_desc', 'test_video', 'courseware', 'cover_img', 'org_ids'));
      });
    },
    async getDomainSelectList() {
      this.loading = true;
      const data = await getLecturerSelectList(this.$route.query.id).finally(() => this.loading = false);
      if (!data || data.error_code) {
        return;
      }
      if (data && data._status_code === 200) {
        this.selectLecturerList = data.data;
      }
    },
    uploadVideoSuccess(data) {
      this.jjsp = data.file_resource_id;
    },
    uploadFileSuccess(data, list) {
      this.zskj = list;
    },
    uploadImgSuccess(data) {
      this.fmt = data.file_resource_id;
    },
    handleSubmit(e) {
      e && e.preventDefault();
      this.form.validateFields((err, values) => {
        if (!err) {
          let str = '';
          this.isEdit ? (str = 'editTeacher', values.id = this.id) : (str = 'addTeacher', values.training_id = this.$route.query.id);
          let list = this.zskj;
          let arr = [];
          list.forEach(item => arr.push(item.file_resource_id));
          values.test_video = this.jjsp;
          values.courseware = arr + '';
          values.cover_img = this.fmt;
          values.org_ids = this.jxs;
          this.save(str, values);
        }
      });
    },
    async save(str, values) {
      this.loading = true;
      const data = await this[str](values).finally(() => this.loading = false);
      if (!data || data.error_code) {
        return;
      }
      if (data && data._status_code === 200) {
        this.$message.success(data.message || '保存成功！');
        this.$emit('success');
        this.clear();
      } else {
        this.$message.error(data.message);
      }
    },
    clear() {
      this.jxs = '';
      this.title = '';
      this.jjsp = '';
      this.zskj = [];
      this.fmt = '';
      this.visible = false;
    },
  },
};
</script>

<style scoped>

</style>
