<template>
  <a-modal v-model="visible"
           :title="title"
           :mask-closable="false"
           :width="700"
           :confirm-loading="loading"
           :centered="true"
           :after-close="afterClosed"
           @ok="save"
           @cancel="close()">
    <a-form-model ref="formRef"
                  :model="form"
                  :rules="rules"
                  :label-col="{span: 6}"
                  :wrapper-col="{span: 18}"
                  label-align="right"
                  class="form-model flex-grow">
      <a-form-model-item label="选择讲师" prop="teacher_id" class="form-item">
        <a-select placeholder="请选择讲师" v-model="form.teacher_id">
          <a-select-option v-for="item in teacherList"
                           :value="item.id"
                           :key="item.id"
                           :disabled="item.disabled">
            {{ item.name }}
          </a-select-option>
        </a-select>
      </a-form-model-item>

      <a-form-model-item label="讲师介绍" prop="teacher_desc" class="form-item">
        <a-textarea placeholder="请输入讲师介绍信息" v-model="form.teacher_desc" allow-clear/>
      </a-form-model-item>

      <a-form-model-item label="封面图" class="form-item">
        <Uploader v-model="form.cover_img" accept="image/*" :custom-file-list="form.cover_img_resource">
          <a-button>
            <a-icon type="upload"/>
            上传
          </a-button>
        </Uploader>
      </a-form-model-item>

      <a-form-model-item label="试讲视频" class="form-item">
        <Uploader v-model="form.test_video" accept="video/*" :custom-file-list="form.test_video_resource"
                  :multiple="true">
          <a-button>
            <a-icon type="upload"/>
            上传
          </a-button>
        </Uploader>
      </a-form-model-item>

      <a-form-model-item label="展示课件" class="form-item">
        <Uploader v-model="form.courseware" :custom-file-list="form.courseware_resource" :multiple="true">
          <a-button>
            <a-icon type="upload"/>
            上传
          </a-button>
        </Uploader>
      </a-form-model-item>
      <a-form-model-item label="可选经销商" v-if="visible" prop="org_ids" class="form-item">
        <SelectOrg v-model="form.org_ids"></SelectOrg>
      </a-form-model-item>
    </a-form-model>
  </a-modal>
</template>

<script>
import {
  getTeacherDetail,
  getTeacherListForSelect,
  addTeacherDetail,
  editTeacherDetail,
} from '../api/config.teacher';

import SelectOrg from '@/components/select/SelectOrg';
import Uploader from '@/components/upload/Uploader';


const defaultForm = () => ({
  teacher_id: undefined,
  teacher_desc: '',
  test_video: '',
  courseware: '',
  cover_img: '',
  org_ids: '',
  cover_img_resource: [],
  test_video_resource: [],
  courseware_resource: [],
});
const savedParams = [
  'teacher_id',
  'teacher_desc',
  'test_video',
  'courseware',
  'cover_img',
  'org_ids',
];

export default {
  name: 'AddTeacherModal',
  components: {
    SelectOrg,
    Uploader,
  },
  props: {
    trainingId: { type: [Number, String], default: '' },
  },
  computed: {
    title() {
      return this.id ? '修改讲师信息' : '添加讲师信息';
    },
  },
  data() {
    return {
      loading: false,
      uploading: false,
      visible: false,
      id: '',

      teacherIds: '', // 不显示出来的讲师 id （因为已经添加过了）
      teacherList: [],

      form: { ...defaultForm() },
      rules: {
        teacher_id: [{ required: true, trigger: 'blur', message: '请选择讲师' }],
        teacher_desc: [{ required: true, trigger: 'blur', message: '请填写讲师介绍信息' }],
        org_ids: [{ required: true, trigger: 'change', message: '请选择经销商' }],
      },
    };
  },
  methods: {
    show({ id, teacherIds }) {
      this.id = id;
      this.teacherIds = teacherIds || '';
      this.getTeacherList();
      this.getData();

      this.visible = true;
    },
    close() {
      this.visible = false;
    },
    afterClosed() {
      this.id = '';
      this.teacherIds = '';
      this.form = { ...defaultForm() };
    },

    save() {
      this.$refs.formRef.validate(valid => {
        if (valid) {
          if (this.id) {
            this.edit();
          } else {
            this.create();
          }
        }
      });
    },

    async create() {
      if (this.loading) {
        return;
      }
      this.loading = true;

      const params = new FormData();
      params.append('training_id', this.trainingId);
      savedParams.forEach((i) => {
        params.append(i, this.form[i]);
      });

      const data = await addTeacherDetail(params).finally(() => {
        this.loading = false;
      });

      if (!data || data.error_code) {
        this.$message.error(data?.message || '保存失败');
        return;
      }
      this.$message.success(data.message || '保存成功');
      this.$emit('saved', { created: true, ...this.form });
      this.close();
    },

    async edit() {
      if (this.loading) {
        return;
      }
      this.loading = true;

      const params = new FormData();
      params.append('id', this.id);
      savedParams.forEach((i) => {
        params.append(i, this.form[i]);
      });

      const data = await editTeacherDetail(params).finally(() => {
        this.loading = false;
      });

      if (!data || data.error_code) {
        this.$message.error(data?.message || '保存失败');
        return;
      }
      this.$message.success(data.message || '保存成功');
      this.$emit('saved', { created: false, ...this.form });
      this.close();
    },

    async getData() {
      if (!this.id || this.loading) {
        return;
      }
      this.loading = true;

      const data = await getTeacherDetail({ id: this.id }).finally(() => {
        this.loading = false;
      });

      if (!data || data.error_code) {
        this.$message.error(data?.message || '获取讲师信息失败');
        return;
      }
      this.form = {
        ...(data.data || {}),
        test_video: data.data?.test_video ? data.data.test_video.join(',') : '',
        courseware: data.data?.courseware ? data.data.courseware.join(',') : '',
        cover_img: data.data?.cover_img ? data.data.cover_img.join(',') : '',
      };
    },

    async getTeacherList() {
      const data = await getTeacherListForSelect({
        training_id: this.trainingId,
      });
      if (!data || data.error_code) {
        this.$message.error(data?.message || '获取讲师列表失败');
        return;
      }
      const teacherIds = this.teacherIds.split(',').map((i) => +i);
      this.teacherList = (data?.data || []).map(
        (i) => {
          if (teacherIds.includes(i.id)) {
            i.disabled = true;
          }
          return i;
        },
      );
    },
  },
};
</script>

<style scoped lang="scss">
.uploader {
  display: inline-block;
  width: auto;
}

.uploaded-file-wrapper {
  position: relative;
  max-width: 339px;
  white-space: pre-wrap;
  word-wrap: break-word;

  .action {
    position: absolute;
    top: 0;
    right: 0;

    .btn {
      color: #FFFFFF;
      background-color: rgba(1, 1, 1, 0.4);

      &:hover {
      }
    }
  }
}

.file-image {
  max-width: 339px;
  max-height: 238px;
}
</style>
