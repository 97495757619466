<template>
  <div>
    <div class="right-top-btn-group">
      <a-button @click="addSort">添加分类</a-button>
    </div>

    <div class="sortList" v-for="(item,index) in sort" :key="index">
      <span>{{ item.title }}</span>
      <div class="sortItem" v-for="(child,tindex) in item.childs" :key="tindex">
        <div class="action">
          <span>{{ child.title }}</span>
          <span><a @click="edit(child.id,item.id,child.title)">编辑</a><a @click="del(child.id, child)">删除</a></span>
        </div>
        <div class="sortSub" v-for="(grand,cindex) in child.childs" :key="cindex">
          <div class="action">
            <span>{{ grand.title }}</span>
            <span><a @click="edit(grand.id,child.id,grand.title)">编辑</a><a @click="del(grand.id, grand)">删除</a></span>
          </div>
        </div>
      </div>
    </div>
    <a-modal :title="'添加分类'"
             :visible="visible"
             :confirm-loading="confirmLoading"
             :after-close="afterModalClosed"
             @ok="confirmModal"
             @cancel="closeModal">
      <a-form>
        <a-form-item label="分类名称">
          <a-input v-model="modalInputTxt" placeholder="输入分类名称"></a-input>
        </a-form-item>
        <a-form-item label="父级层级">
          <a-select v-model="selectedClassesId" placeholder="父级层级">
            <a-select-option v-for="item of parentList" :key="item.id" :value="item.id">
              {{ item.title }}
            </a-select-option>
          </a-select>
        </a-form-item>
      </a-form>
    </a-modal>
    <a-modal :title="'编辑分类'"
             :visible="editVisible"
             :confirm-loading="confirmLoading"
             :after-close="afterModalClosed"
             @ok="editModal"
             @cancel="closeModal">
      <a-form>
        <a-form-item label="分类名称">
          <a-input v-model="editTitle" placeholder="输入分类名称"></a-input>
        </a-form-item>
        <a-form-item label="父级层级">
          <a-select v-model="editPid" placeholder="父级层级">
            <a-select-option v-for="item of parentList" :key="item.id" :value="item.id">
              {{ item.title }}
            </a-select-option>
          </a-select>
        </a-form-item>
      </a-form>
    </a-modal>
  </div>
</template>

<script>
import { getCategories, addCategories, delCategories } from '@/api/offline/Details';

export default {
  name: 'Category',
  data() {
    return {
      sort: [
        {
          'id': 1,
          'name': '集中培训',
          'children': [
            {
              'id': 11,
              'name': '产品培训',
              'parentId': 1,
              'children': [
                {
                  'id': 111,
                  'name': '产品培训1',
                  'parentId': 11,
                },
              ],
            },
            {
              'id': 12,
              'name': '认证培训',
              'parentId': 1,
            },
          ],
        },
        {
          'id': 2,
          'name': '辅导业务',
          'children': [
            {
              'id': 21,
              'name': '销售指标辅导',
              'parentId': 2,
              'children': [
                {
                  'id': 321,
                  'name': '销售指标辅导1',
                  'parentId': 21,
                },
              ],
            },
            {
              'id': 22,
              'name': '车展辅导',
              'parentId': 2,
            },
          ],
        },
      ],
      parentList: [
        { id: 0, title: '集中培训' },
        { id: 1, title: '辅导业务' },
      ],
      editId: '',
      editPid: '',
      editTitle: '',
      visible: false,
      editVisible: false,
      confirmLoading: false,
      modalInputTxt: '',//添加分类名
      selectedClassesId: undefined,
    };
  },
  created() {
    this.getData();
  },
  methods: {
    async getData() {
      try {
        const data = await getCategories({}).finally();
        if (!data || data.error_code) {
          return;
        }
        this.sort = data?.data || [];
        this.parentList = this.formatClasses(data.data);
      } catch (error) {
        console.log(error);
      }
    },
    del(id, item) {
      if (item.child?.length) {
        this.$message.info('当前分类有下级分类，不允许删除');
        return;
      }
      this.$confirm({
        content: () => <div>确认删除？</div>,
        onOk: async () => {
          const params = {
            id: id,
          };
          const data = await delCategories(params).finally();
          if (!data || data.error_code) {
            this.$message.error(data.message);
            return;
          }
          this.$message.success(data.message);
          this.getData();
        },
      });
    },
    // 修改分类
    edit(id, pid, tit) {
      this.editId = id;
      this.editPid = pid;
      this.editTitle = tit;
      this.editVisible = true;
    },
    //确认编辑
    async editModal() {
      if (this.editTitle === '') {
        this.$message.info('请填写分类名称！');
        return;
      }
      const params = {
        id: this.editId,
        title: this.editTitle,
        parent_id: this.editPid,
      };
      const data = await addCategories(params);
      if (!data || data.error_code) {
        return;
      }
      this.editVisible = false;
      this.$message.info(data.message);
      this.getData();
    },
    // 添加分类弹窗
    addSort() {
      this.visible = true;
    },
    // 整理分类父级列表
    formatClasses(data) {
      const list = [];
      data.forEach(obj => {
        list.push({ id: obj.id, title: obj.title });
        obj.childs.forEach(item => {
          list.push({ id: item.id, title: item.title });
        });
      });
      return list;
    },
    afterModalClosed() {

    },
    async confirmModal() {
      if (this.modalInputTxt === '') {
        this.$message.info('请填写分类名称！');
        return;
      }
      if (this.selectedClassesId === undefined) {
        this.$message.info('请选择父级分类！');
        return;
      }
      try {
        const params = {
          title: this.modalInputTxt,
          parent_id: this.selectedClassesId,
        };
        const data = await addCategories(params).finally();
        if (!data || data.error_code) {
          this.$message.error(data.message);
          return;
        }
        this.$message.success(data.message);
        this.getData();
      } catch (error) {
        console.log(error);
      }
      this.visible = false;
      this.modalInputTxt = '';
      this.selectedClassesId = undefined;
    },
    closeModal() {
      this.visible = false;
      this.editVisible = false;
      this.modalInputTxt = '';
      this.selectedClassesId = undefined;
    },
  },
};
</script>

<style scoped lang="scss">
.sortList {
  width: 80%;
  padding-left: 30px;
  margin-bottom: 20px;

  .sortItem {
    padding-left: 10px;
    margin: 10px 0;

    .action {
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-bottom: 1px dashed #333;

      span {
        a {
          margin-left: 5px;
        }
      }
    }

    .sortSub {
      padding-left: 15px;
      margin: 10px 0;
    }
  }
}
</style>
