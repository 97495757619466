<template>
  <div class="text-center">
    <a-button
      type="primary"
      @click="saveAndNext"
      v-if="showNext"
      :loading="loading"
    >
      {{ nextText }}
    </a-button>
    <a-button
      v-if="showSave"
      type="primary"
      @click="handleSubmit"
      ghost
      :loading="loading"
    >
      保存本页
    </a-button>
    <a-button @click="goBack"> 取消</a-button>
  </div>
</template>

<script>
export default {
  name: 'saveButtons',
  props: {
    showNext: {
      type: Boolean,
      default: true,
    },
    showSave: {
      type: Boolean,
      default: true,
    },
    nextText: {
      type: String,
      default: '保存并下一步',
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    goBack() {
      this.$confirm({
        title: '提示',
        content: '是否退出本页?',
        onOk: () => {
          this.$router.push('/index/offline/training/list');
        },
      });
    },
    handleSubmit() {
      this.$emit('handleSubmit');
    },
    saveAndNext() {
      this.$emit('saveAndNext');
    },
  },
};
</script>

<style scoped lang="scss">
.text-center {
  width: 100%;
  padding-top: 20px;
  padding-bottom: 20px;
  text-align: center;
}
</style>
