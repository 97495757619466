<template>
  <div>
    <div class="right-top-btn-group">
      <a-button @click="addForm">创建表单</a-button>
    </div>

    <div class="mb-10 ph-20 flex flex-start flex-wrap">
      <div class="flex flex-start flex-wrap mr-10">
        <span>表单名称：</span>
        <a-input v-model="formName" :allow-clear="true" style="width: 220px" placeholder="请输入表单名称"/>
      </div>
      <div class="flex flex-start flex-wrap">
        <a-button :disabled="readOnly" type="primary" @click="search()">搜索</a-button>
        <a-button @click="reset">重置</a-button>
      </div>
    </div>

    <div class="ph-20">
      <a-table :columns="columns"
               :data-source="data"
               :pagination="false"
               :loading="loading"
               bordered
               row-key="id">
        <span slot="created_at" slot-scope="text">{{ text.substring(0, 10) }}</span>
        <span slot="training" slot-scope="text">{{ text.title }}</span>
        <span slot="action" slot-scope="text, record">
          <a class="pl-5 pr-5" @click="toDetails(record.id,'edit')">编辑</a>
          <a class="pl-5 pr-5" @click="operateForms(record.id,record.status)">{{
              record.status === 1 ? '禁用' : '启用'
            }}</a>
          <a class="pl-5 pr-5" @click="download(record.id,record.training.id)">下载表单</a>
          <a class="pl-5 pr-5" @click="toDetails(record.id,'copy')">复制表单</a>
        </span>
        <template slot="footer">
          <Pagination v-if="pagination && pagination.total"
                      :pagination="pagination"
                      class="pagination"
                      @change="onPageChange"
                      @showSizeChange="onSizeChange"/>
        </template>
      </a-table>
    </div>
  </div>
</template>

<script>
import Pagination, {
  getPagination,
  updatePagination,
} from '@components/Pagination';

import {
  getForms,
  operateForms,
  downloadForm,
} from './api';

export default {
  name: 'offlineForm',
  components: { Pagination },
  data() {
    return {
      formName: '',//搜索表单名称
      loading: false,
      readOnly: false,
      pagination: getPagination(),
      columns: [
        {
          width: '',
          align: 'center',
          dataIndex: 'title',
          key: 'title',
          title: '表单名称',
        },
        {
          width: '',
          align: 'center',
          dataIndex: 'created_at',
          key: 'created_at',
          title: '创建时间',
          scopedSlots: { customRender: 'created_at' },
        },
        {
          width: '',
          align: 'center',
          dataIndex: 'training',
          key: 'training',
          title: '对应业务',
          scopedSlots: { customRender: 'training' },
        },
        {
          width: '',
          align: 'center',
          dataIndex: 'status_txt',
          key: 'status_txt',
          title: '状态',
        },
        {
          width: '',
          title: '操作',
          align: 'center',
          key: 'action',
          scopedSlots: { customRender: 'action' },
        },
      ],
      data: [],
    };
  },
  created() {
    this.getFormList(1, this.pagination.defaultPageSize);
  },
  destroyed() {
  },
  methods: {
    // 获取表单列表
    async getFormList(page, pageSize) {
      page = page || 1;
      pageSize = pageSize || this.pagination.defaultPageSize;
      const params = {
        title: this.formName,
      };
      const data = await getForms({
        page: page,
        per_page: pageSize,
        ...params,
      }).finally(() => this.loading = false);
      if (!data || data.error_code) {
        return;
      }
      this.data = data?.data || [];

      const pagination = data?.meta || {};
      this.pagination = updatePagination(this.pagination, pagination);
    },
    // 启用/弃用表单
    operateForms(id, status) {
      this.$confirm({
        content: () => <div>确认操作？</div>,
        onOk: async () => {
          const params = {
            id: id,
            action: status === 1 ? 'disable' : 'enable',//操作动作 enable: 启用 disable: 禁用
          };
          const data = await operateForms(params);
          if (!data || data.error_code) {
            this.$message.error(data.message);
            return;
          }
          this.$message.success(data.message);
          this.getFormList();
        },
      });
    },
    toDetails(id, type) {
      this.$router.push({
        path: '/index/offline/form/detail',
        query: {
          id: id,
          type: type,
        },
      });
    },
    // 下载表单
    async download(formId, trainingId) {
      const data = await downloadForm({
        form_id: formId,
        training_id: trainingId,
      });
      if (!data || data.error_code) {
        this.$message.error('下载表单失败');
        return;
      }
      this.$message.success('下载表单成功');
    },
    // 新建表单
    addForm() {
      this.$router.push({
        path: '/index/offline/form/create',
        query: {
          id: 0,
          type: 'new',
        },
      });
    },
    search() {
      this.getFormList();
    },
    // 重置
    reset() {
      this.formName = '';
      this.getFormList();
    },
    onPageChange(page, pageSize) {
      this.getFormList(page, pageSize);
    },
    onSizeChange(current, size) {
      this.getFormList(1, size);
    },
  },
};
</script>

<style scoped lang="scss">

</style>
