<template>
  <div class="pd20">
    <div class="flex-box">
      <div class="flex-box pb20">
        <span class="w50 lh30">名称：</span>
        <a-input placeholder="请输入" class="mr10" v-model="keyWord"/>
        <searchAndReset
          @handleSearch="handleSearch"
          @getData="getData"
        ></searchAndReset>
      </div>
      <a-button @click="openLayer" type="primary"> 添加直播课</a-button>
    </div>
    <a-table
      :columns="columns"
      :data-source="data"
      bordered
      :pagination="pagination"
      @change="handleTableChange"
      :loading="loading"
    >
      <template slot="start_dates" slot-scope="text, record">
        {{ text }} - {{ record.end_dates }}
      </template>
      <template slot="action" slot-scope="text, record">
        <a @click="deleteData(record)">删除</a>
      </template>
    </a-table>
    <saveButtons
      ref="saveButtons"
      @handleSubmit="handleSubmit"
      @saveAndNext="saveAndNext"
    ></saveButtons>
    <addLiveClassLayer ref="layer" @saveSuccess="getData"></addLiveClassLayer>
  </div>
</template>

<script>
import { getLiveList, liveClassDel } from '@/api/config';
import { ConfigMixin } from './mixins/configMixins';

export default {
  name: 'liveClassConfiguration',
  mixins: [ConfigMixin],
  components: {
    addLiveClassLayer: () => import('./components/addLiveClassLayer'),
    searchAndReset: () => import('./components/searchAndReset'),
    saveButtons: () => import('./components/saveButtons'),
  },
  data() {
    return {
      columns: [
        {
          title: '名称',
          dataIndex: 'title',
          scopedSlots: { customRender: 'title' },
        },
        {
          title: '直播时间',
          dataIndex: 'start_dates',
          scopedSlots: { customRender: 'start_dates' },
        },
        {
          title: '讲师',
          dataIndex: 'teacher_title',
          scopedSlots: { customRender: 'teacher_title' },
        },
        {
          title: '班次',
          dataIndex: 'classes_name',
          scopedSlots: { customRender: 'classes_name' },
        },
        {
          title: '操作',
          dataIndex: 'action',
          scopedSlots: { customRender: 'action' },
        },
      ],
      data: [],
      keyWord: '',
      pagination: {},
      loading: false,
    };
  },
  created() {
    this.getData();
  },
  methods: {
    handleTableChange() {
      this.handleSearch();
    },
    getData() {
      this.keyWord = '';
      this.pagination.current = 1;
      this.pagination.pageSize = 10;
      this.handleSearch();
    },
    handleSearch() {
      let params = `?training_id=${this.id}&page=${this.pagination.current}&per_page=${this.pagination.pageSize}&keyword=${this.keyWord}&classes_id=`;
      this.getApiData(params);
    },
    async getApiData(params) {
      this.loading = true;
      const data = await getLiveList(params).finally(
        () => (this.loading = false),
      );
      if (!data || data.error_code) {
        return;
      }
      if (data) {
        this.data = data.data.data;
        this.pagination.total = data.data.total;
      }
    },
    openLayer() {
      if (this.$refs.layer) {
        this.$refs.layer.visible = true;
      }
    },
    async delApiData(record) {
      this.loading = true;
      const data = await liveClassDel({ id: record.id }).finally(
        () => (this.loading = false),
      );
      if (!data || data.error_code) {
        this.$message.error(data.message);
        return;
      }
      if (data) {
        this.$message.success(data.message);
        this.getData();
      }
    },
    handleSubmit() {
      this.$message.success('保存成功');
      this.goNextStep('exam');
    },
  },
};
</script>

<style scoped>
.flex-box {
  display: flex;
  justify-content: space-between;
}

.pd20 {
  padding: 20px;
}

.text-center {
  text-align: center;
}

.w50 {
  width: 100px;
}

.mr10 {
  margin-right: 10px;
}

.pb20 {
  padding-bottom: 20px;
}

.lh30 {
  line-height: 30px;
}
</style>
