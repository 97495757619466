import moment from 'moment';

function format(dateStr) {
  if (!dateStr) {
    return;
  }
  const dateFormat = /^\d{4,4}-\d{2,2}-\d{2,2}\s+\d{2,2}:\d{2,2}:\d{2,2}$/g.test(dateStr) ? 'YYYY-MM-DD HH:mm:ss' : 'YYYY-MM-DD';
  return moment(dateStr, dateFormat);
}

function getStart(a) {
  return format(a.start || a.start_date || undefined);
}

function getEnd(a) {
  return format(a.end || a.end_date || undefined);
}

function sortByStart(ranges) {
  return ranges.sort((a, b) => {
    if (getStart(a).isBefore(getStart(b))) {
      return -1;
    } else if (!getStart(a).isAfter(getStart(b))) {
      if (getEnd(a).isBefore(getEnd(b))) {
        return -1;
      } else if (!getEnd(a).isAfter(getEnd(b))) {
        return 0;
      }
      return 1;
    }
    return 1;
  });
}

function rearrange(ranges) {
  const tempRanges = sortByStart([...(ranges || [])]);
  const rows = [[]];

  while (tempRanges.length) {
    for (let i = 0; i <= rows.length; i += 1) {
      if (!rows[i]?.length) {
        rows[i] = [...tempRanges.splice(0, 1)];
        break;
      }
      if (getEnd(rows[i][rows[i].length - 1]).isBefore(getStart(tempRanges[0]))) {
        rows[i].push(...tempRanges.splice(0, 1));
        break;
      } else if (!rows[i + 1]?.length) {
        rows[i + 1] = [];
      }
    }
  }

  return rows;
}

export default rearrange;
