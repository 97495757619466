<template>
  <div class="ss-assessment-detail">
    <div class="row row-left">
      <div class="col col-left col-narrow">
        <a-menu v-model="selectedMenu" mode="horizontal" @select="selectMenu">
          <a-menu-item v-for="item of parsedMenus" :disabled="item.id !== '1' && !id" :key="item.id">
            {{ item.title }}
          </a-menu-item>
        </a-menu>
      </div>
    </div>

    <div>
      <router-view/>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { serviceConfiguration } from '@/utils/Navs/OfflineNav';

import { getTrainingType } from './api/api';

export default {
  name: 'configIndex',
  computed: {
    ...mapState({
      trainingType: state => state.Offline.trainType,
      isAppoint: state => state.Offline.isAppoint,
    }),
    parsedMenus() {
      return this.menus.filter(i => {
        // 手动指派开启时，不显示报名配置页
        if (this.isAppoint === 1 && i.id === '4') {
          return false;
        }

        // 培训不显示讲师预约
        if (this.trainingType === 1 && i.id !== '5') {
          return true;
        }
        // 辅导不显示班次和直播课
        if (this.trainingType === 2 && i.id !== '6' && i.id !== '8' && i.id !== '14') {
          return true;
        }

        return false;
      });
    },
  },
  watch: {
    '$route.query.id'(id) {
      this.id = id || this.id;
    },
    '$route.path': {
      immediate: true,
      handler(path) {
        this.menus.forEach(menu => {
          if (menu.path === path) {
            this.selectedMenu = [menu.id];
          }
        });
      },
    },
  },
  data() {
    return {
      id: null,
      menus: serviceConfiguration,
      selectedMenu: ['1'],
    };
  },
  created() {
    this.id = this.$route.query?.id || null;

    this.getTrainingType();
  },
  beforeRouteEnter(to, from, next) {
    next(vm => vm.executeSelection(to?.name || ''));
  },
  beforeRouteUpdate(to, from, next) {
    this.executeSelection(to?.name || '');
    next();
  },
  methods: {
    async getTrainingType() {
      const data = await getTrainingType({
        training_id: this.id,
      });
      this.$store.commit('Offline/updateTrainType', data.data?.type || 2);
      this.$store.commit('Offline/updateIsAppoint', data.data?.is_appoint || 0);
      this.$store.commit('Offline/updateStage', data.data?.stage || []);
    },
    executeSelection(name) {
      this.menus.forEach(menu => {
        if (menu.name === name) {
          this.selectedMenu = [menu.id];
        }
      });
    },
    selectMenu({ key }) {
      this.id = this.$route.query?.id || null;
      if (+key > 1 && !this.id) {
        this.selectedMenu = ['1'];
        this.$message.warning({ content: '请先保存' });
        return;
      }
      const path = this.getPath(key) + (this.id ? `?id=${this.id}` : '');
      this.$router.replace(path);
    },
    getPath(key) {
      const [{ path }] = this.menus.filter(menu => menu.id === key + '' && menu.path);
      return path || '';
    },
  },
};
</script>

<style scoped lang="scss">
.ss-assessment-detail {
  width: 100%;

  .sub-txt {
    font-size: 12px;
    color: $light02;
  }
}
</style>
