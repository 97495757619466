import request from '@/utils/Request';

// 班次配置 —— 查询班次下拉列表
export function getClassSelectList(params) {
  return request({
    url: '/offline/admin/training/class/select-list',
    method: 'get',
    params,
  });
}

// 讲师列表(下拉列表)
export function getTeacherSelectList(params) {
  return request({
    url: '/offline/admin/teachers/select-list',
    method: 'get',
    params,
  });
}

// 班次配置 —— 查询班次详情
export function getClassDetail(params) {
  return request({
    url: '/offline/admin/training/class/show',
    method: 'get',
    params,
  });
}

// 班次配置 —— 保存详情
export function saveClassDetail(data) {
  return request({
    url: '/offline/admin/training/class',
    method: 'post',
    data
  });
}
