import request from '@/utils/Request';

// 获取业务列表
export function getTrainList(params) {
  return request({
    url: '/offline/admin/training',
    method: 'get',
    params,
  });
}

// 删除业务列表
export function delTrainList(data) {
  return request({
    url: '/offline/admin/training/bases/delete',
    method: 'post',
    data,
  });
}

// 上/下架培训
export function postTrainOperate(data) {
  return request({
    url: '/offline/admin/training/bases/operate',
    method: 'post',
    data,
  });
}

// 获取业务分类下拉列表
export function getCategoriesList(params) {
  return request({
    url: '/offline/admin/training/categories/select-list',
    method: 'get',
    params,
  });
}

// 结算业务
export function getSettlement(params) {
  return request({
    url: '/offline/admin/Settlement/index',
    method: 'get',
    params,
  });
}

// 结算数据记录
export function getSettlementRecord(params) {
  return request({
    url: '/offline/admin/Settlement/excellog',
    method: 'get',
    params,
  });
}
