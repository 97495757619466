import request from '@/utils/Request';

// 讲师列表
export function getTeacherList(params) {
  return request({
    url: '/offline/admin/teacherconf',
    method: 'get',
    params,
  });
}

// 讲师详情
export function getTeacherDetail(id) {
  return request({
    url: `/offline/admin/teacherconf/${id}`,
    method: 'get',
  });
}

// 添加讲师
export function saveTeacher(data) {
  return request({
    url: '/offline/admin/teacherconf/save',
    method: 'post',
    data,
  });
}

// 删除讲师
export function deleteTeacher(id) {
  return request({
    url: `/offline/admin/teacherconf/${id}`,
    method: 'delete',
  });
}

// 培训讲师能看到的菜单
export function getTeacherAccessedMenu() {
  return request({
    url: `/public/conf/offline/teacherconf/lookat`,
    method: 'get',
  });
}

