const prefix = '/index/offline/';

// 业务详情
const trainDetail = [
  {id: '1', title: '报名/结业统计', name: 'trainSign', path: '/index/offline/trainDetails/trainSign'},
  {id: '2', title: '考勤打卡统计', name: 'trainCheck', path: '/index/offline/trainDetails/trainCheck'},
  {id: '3', title: '考试成绩统计', name: 'trainExam', path: '/index/offline/trainDetails/trainExam'},
  {id: '4', title: '直播统计', name: 'trainLive', path: '/index/offline/trainDetails/trainLive'},
  {id: '5', title: '作业统计', name: 'trainWork', path: '/index/offline/trainDetails/trainWork'},
  {id: '6', title: '学员提交材料', name: 'trainSubmitStudent', path: '/index/offline/trainDetails/submitStudent'},
  {id: '9', title: '讲师提交材料', name: 'trainSubmitTeacher', path: '/index/offline/trainDetails/submitTeacher'},
  {id: '7', title: '行动计划统计', name: 'trainAction', path: '/index/offline/trainDetails/trainAction' },
  {id: '8', title: '培训结果汇总', name: 'trainSummary', path: '/index/offline/trainDetails/trainSummary'},
];

// 业务配置
const serviceConfiguration = [
  {id: '1', title: '基础配置', name: 'basicConfiguration', path: '/index/offline/config/basicConfiguration'},
  {id: '2', title: '页面信息配置', name: 'pageInfoConfiguration', path: '/index/offline/config/pageInfoConfiguration'},
  {id: '3', title: '预习内容配置', name: 'previewContentsConfiguration', path: '/index/offline/config/previewContentsConfiguration'},
  {id: '4', title: '报名配置', name: 'signUpConfiguration', path: '/index/offline/config/signUpConfiguration'},
  {id: '5', title: '讲师预约', name: 'instructorAppointment', path: '/index/offline/config/instructorAppointment'},
  {id: '6', title: '班次配置', name: 'classConfiguration', path: '/index/offline/config/classConfiguration'},
  {id: '7', title: '考勤配置', name: 'checkWorkAttendanceConfiguration', path: '/index/offline/config/checkWorkAttendanceConfiguration'},
  {id: '8', title: '直播课配置', name: 'liveClassConfiguration', path: '/index/offline/config/liveClassConfiguration'},
  {id: '9', title: '考试配置', name: 'Exam', path: '/index/offline/config/exam'},
  {id: '10', title: '调研配置', name: 'surveyConfiguration', path: '/index/offline/config/surveyConfiguration'},
  {id: '11', title: '提交材料配置', name: 'materialConfiguration', path: '/index/offline/config/materialConfiguration'},
  {id: '12', title: '培训资料上传', name: 'trainingMaterialsUpload', path: '/index/offline/config/trainingMaterialsUpload'},
  {id: '13', title: '条件配置', name: 'completionConditionsConfiguration', path: '/index/offline/config/completionConditionsConfiguration'},
  {id: '14', title: '培训计划信息', name: 'trainPlanConfig', path: '/index/offline/config/trainPlanConfig'},

];

const navs = [
  {
    id: '1', label: '培训配置', path: '/', children: [
      {
        id: '1-1', label: '培训列表', path: `${prefix}training/list`, childrenPath: [
          ...serviceConfiguration.map((i) => i.path),
          ...trainDetail.map((i) => i.path),
        ],
      },
      {
        id: '1-2', label: '业务分类管理', path: `${prefix}category`,
      },
      {
        id: '1-3', label: '表单管理', path: `${prefix}form/list`, childrenPath: [
          '/index/offline/form/detail',
          '/index/offline/form/create',
        ],
      },
      {
        id: '1-4', label: '辅导报名审批', path: `${prefix}signApproval`,
      },
      {
        id: '1-5', label: '辅导预约审批', path: `${prefix}orderApproval`,
      },
      {
        id: '1-6', label: '讲师配置', path: `${prefix}teacher/list`,
      },
      {
        id: '1-7', label: '培训计划导出', path: `${prefix}trainPlanExport`,
      },
    ],
  },
];

export {
  trainDetail,
  serviceConfiguration,
};
export default navs;
