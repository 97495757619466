<template>
  <div class="main">
    <commonTitle v-if="trainingType === 1" class="mb10">结业条件</commonTitle>
    <formList v-if="trainingType === 1">
      <template #lt>出勤率不低于</template>
      <template #mid>
        <a-input-number v-model.trim="form.graduation_attendance_rate" :min="1" :max="100"/>
        %
      </template>
      <template #rt>
        <a-switch v-model="data.checkCql"/>
      </template>
    </formList>
    <formList v-if="trainingType === 1">
      <template #lt>考试全部通过</template>
      <template #rt>
        <a-switch v-model="data.checkKs"/>
      </template>
    </formList>
    <formList v-if="trainingType === 1">
      <template #lt>作业全部提交</template>
      <template #rt>
        <a-switch v-model="data.checkZy"/>
      </template>
    </formList>
    <commonTitle class="mt50 mb10">评价条件</commonTitle>
    <formList>
      <template #lt>出勤率不低于</template>
      <template #mid>
        <a-input-number v-model.trim="form.evaluate_attendance_rate" :min="1" :max="100"/>
        %
      </template>
      <template #rt>
        <a-switch v-model="data.checkPj"/>
      </template>
    </formList>
    <saveButtons class="mt20" ref="saveButtons" @handleSubmit="handleSubmit" :showNext="false"></saveButtons>
  </div>
</template>

<script>
import { mapState } from 'vuex';

import { getConditionsDetail, addConditions } from '@/api/config';

export default {
  name: 'completionConditionsConfiguration',
  components: {
    saveButtons: () => import('./components/saveButtons'),
    commonTitle: () => import('./components/commonTitle'),
    formList: () => import('./components/formList'),
  },
  computed: {
    ...mapState({
      trainingType: state => state.Offline.trainType,
    }),
  },
  data() {
    return {
      form: {},
      data: {},
      nowIndex: 0,
    };
  },
  created() {
    this.getData();
  },
  methods: {
    async getData() {
      this.loading = true;
      const data = await getConditionsDetail(this.$route.query.id).finally(() => this.loading = false);
      if (!data || data.error_code) {
        return;
      }
      let datas = data.data;
      if (data && datas) {
        const { graduation_attendance_rate, evaluate_attendance_rate } = datas;
        this.form = datas;
        this.form.graduation_attendance_rate = graduation_attendance_rate ? graduation_attendance_rate : '';
        this.form.evaluate_attendance_rate = evaluate_attendance_rate ? evaluate_attendance_rate : '';

        this.$set(this.data, 'checkCql', +datas.graduation_attendance_switch ? true : false);
        this.$set(this.data, 'checkKs', +datas.graduation_pass_all_exams ? true : false);
        this.$set(this.data, 'checkZy', +datas.graduation_submit_all_homework ? true : false);
        this.$set(this.data, 'checkPj', +datas.evaluate_attendance_switch ? true : false);
        this.data.checkPj = +datas.evaluate_attendance_switch ? true : false;
      }
    },
    handleSubmit() {
      let { graduation_attendance_rate, evaluate_attendance_rate } = this.form;
      if ((this.data.checkCql && !graduation_attendance_rate) || (this.data.checkPj && !evaluate_attendance_rate)) {
        this.$message.error('请补充出勤率！');
        return;
      }
      let { checkCql, checkKs, checkZy, checkPj } = this.data;
      this.form.graduation_attendance_switch = checkCql ? 1 : 0;
      this.form.graduation_pass_all_exams = checkKs ? 1 : 0;
      this.form.graduation_submit_all_homework = checkZy ? 1 : 0;
      this.form.evaluate_attendance_switch = checkPj ? 1 : 0;
      this.form.training_id = this.$route.query.id;
      this.save(this.form);
    },
    async save(params) {
      this.loading = true;
      const data = await addConditions(params).finally(
        () => (this.loading = false),
      );

      if (!data || data.error_code) {
        this.$message.error({ content: data?.message || '保存失败' });
        this.imgList = [];
        return;
      }
      this.$message.success({ content: data?.message || '保存成功' });
    },
  },
};
</script>

<style lang="less" scoped>
.main {
  width: 700px;
  margin: 0 auto;
}

.mb10 {
  margin-bottom: 10px;
}

.mt50 {
  margin-top: 50px;
}

.mt20 {
  margin-top: 20px;
}
</style>
