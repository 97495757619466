<template>
  <div class="schedule">
    <router-view/>
  </div>
</template>

<script>
export default {
  name: 'Index',
};
</script>

<style scoped lang="scss">
.schedule {
  width: 100%;
  height: calc(100vh - 65px);
  transform: translateY(-4px);
  padding: 4px 20px;
  background-color: #F9FAFE;
}
</style>
