<template>
  <div class="pd20">
    <div class="flex-box">
      <div class="flex-box pb20">
        <span class="w50 lh30">类型：</span>
        <a-select placeholder="请选择" class="w200" v-model="type">
          <a-select-option
            v-for="item in selectTypeList"
            :value="item.value"
            :key="item.value"
          >
            {{ item.label }}
          </a-select-option>
        </a-select>
        <span class="w50 lh30">名称：</span>
        <a-input placeholder="请输入" class="w200 mr10" v-model="form.name"/>
        <searchAndReset
          @handleSearch="handleSearch"
          @getData="getData"
        ></searchAndReset>
      </div>
      <a-button @click="openLayer()" type="primary"> 添加资料</a-button>
    </div>
    <a-table
      :row-key="(record) => record.id"
      :columns="columns"
      :data-source="data"
      bordered
      :pagination="false"
      @change="handleTableChange"
      :loading="loading"
    >
      <template slot="action" slot-scope="text, record">
        <a @click="openLayer(record)">编辑</a>
        <a-divider type="vertical"/>
        <a @click="deleteData(record)">删除</a>
      </template>
      <template slot="footer">
        <Pagination
          v-if="pagination && pagination.total"
          :pagination="pagination"
          class="pagination"
          @change="onPageChange"
          @showSizeChange="onSizeChange"
        ></Pagination>
      </template>
    </a-table>
    <saveButtons
      ref="saveButtons"
      :showSave="false"
      next-text="下一步"
      @handleSubmit="handleSubmit"
      @saveAndNext="saveAndNext"
    ></saveButtons>
    <addDataLayer ref="layer" @saveSuccess="getData"></addDataLayer>
  </div>
</template>

<script>
import { getFileList, fileListDel } from '@/api/config';
import { ConfigMixin } from './mixins/configMixins';
import { Divider } from 'ant-design-vue';
import dayjs from 'dayjs';
import Pagination from '@components/Pagination';
import { mapState } from 'vuex';


export default {
  name: 'trainingMaterialsUpload',
  mixins: [ConfigMixin],
  components: {
    addDataLayer: () => import('./components/addDataLayer'),
    searchAndReset: () => import('./components/searchAndReset'),
    saveButtons: () => import('./components/saveButtons'),
    'a-divider': Divider,
    Pagination,
  },
  computed: {
    ...mapState({
      trainingType: state => state.Offline.trainType,
      isAppoint: state => state.Offline.isAppoint,
    }),
    columns() {
      return [
        {
          title: '类型',
          dataIndex: 'file_type_txt',
          scopedSlots: { customRender: 'file_type_txt' },
        },
        {
          title: '名称',
          dataIndex: 'file_title',
          scopedSlots: { customRender: 'file_title' },
        },
        {
          hidden: true,
          title: '辅导阶段',
          dataIndex: 'stage',
          customRender: (text) => `第${text}阶段`,
        },
        {
          title: '上传时间',
          dataIndex: 'time',
          scopedSlots: { customRender: 'time' },
          customRender: (text) => {
            return dayjs(text * 1000).format('YYYY-MM-DD HH:mm:ss');
          },
        },
        {
          title: '上传人',
          dataIndex: 'username',
          scopedSlots: { customRender: 'username' },
        },
        {
          title: '操作',
          dataIndex: 'action',
          scopedSlots: { customRender: 'action' },
        },
      ].filter((i) => this.trainingType === 2 || !i.hidden);
    },
  },
  data() {
    return {
      data: [],
      form: {},
      selectTypeList: [
        {
          value: '1',
          label: '文档',
        },
        {
          value: '2',
          label: '表格',
        },
        {
          value: '3',
          label: 'pdf',
        },
        {
          value: '4',
          label: 'ppt',
        },
        {
          value: '5',
          label: '图片',
        },
        {
          value: '6',
          label: '视频',
        },
        {
          value: '7',
          label: '音频',
        },
        {
          value: '8',
          label: '其他',
        },
      ],
      pagination: {},
      loading: false,
      type: undefined,
    };
  },
  created() {
    this.getData();
  },
  methods: {
    handleTableChange() {
      this.handleSearch();
    },
    getData() {
      this.keyWord = '';
      this.form.name = '';
      this.type = undefined;
      this.pagination.current = 1;
      this.pagination.pageSize = 10;
      this.handleSearch();
    },
    handleSearch() {
      let type = this.type ? this.type : '';
      let params = `?training_id=${this.id}&page=${this.pagination.current}&per_page=${this.pagination.pageSize}&keyword=${this.form.name}&type=${type}`;
      this.getApiData(params);
    },
    async getApiData(params) {
      this.loading = true;
      const data = await getFileList(params).finally(
        () => (this.loading = false),
      );
      if (!data || data.error_code) {
        return;
      }
      if (data) {
        this.data = data.data.data;
        this.pagination.total = data.data.total;
      }
    },
    openLayer(record) {
      this.$nextTick(() => {
        this.$refs.layer.openMe(record);
      });
    },
    async delApiData(item) {
      this.loading = true;
      const data = await fileListDel({ id: item.id }).finally(
        () => (this.loading = false),
      );
      if (!data || data.error_code) {
        this.$message.error(data.message);
        return;
      }
      if (data) {
        this.$message.success(data.message);
        this.getData();
      }
    },
    handleSubmit() {
      this.goNextStep('completionConditionsConfiguration');
    },
  },
};
</script>

<style scoped>
.flex-box {
  display: flex;
  justify-content: space-between;
}

.pd20 {
  padding: 20px;
}

.text-center {
  text-align: center;
}

.w50 {
  width: 100px;
}

.mr10 {
  margin-right: 10px;
}

.pb20 {
  padding-bottom: 20px;
}

.lh30 {
  line-height: 30px;
}

.w200 {
  width: 200px;
}
</style>
