<template>
  <div>
    <a-form
      class="ant-advanced-search-form"
      :form="form"
      @submit="handleSubmit"
      v-bind="formItemLayout"
    >
      <div class="flex-box">
        <div class="wp100">
          <a-form-item label="业务名称">
            <a-input
              placeholder="请输入"
              v-decorator="['title', validatorRules.title]"
            />
          </a-form-item>
          <a-form-item label="业务类别">
            <a-cascader
              v-decorator="['category_id', validatorRules.category_id]"
              :options="selectList"
              placeholder="请选择"
              :fieldNames="fieldNames"
              @change="onChange"
              :getPopupContainer="(triggerNode) => triggerNode.parentNode"
            />
          </a-form-item>
          <a-form-item label="执行方式">
            <a-select
              placeholder="请选择"
              v-decorator="['exec_method', validatorRules.exec_method]"
              :getPopupContainer="(triggerNode) => triggerNode.parentNode"
            >
              <a-select-option
                v-for="item in selectList1"
                :value="item.value"
                :key="item.value"
              >
                {{ item.label }}
              </a-select-option>
            </a-select>
          </a-form-item>
          <a-form-item label="时间判定">
            <a-select
              placeholder="请选择"
              v-decorator="['time_determine', validatorRules.time_determine]"
              :getPopupContainer="(triggerNode) => triggerNode.parentNode"
            >
              <a-select-option
                v-for="item in selectList2"
                :value="item.value"
                :key="item.value"
              >
                {{ item.label }}
              </a-select-option>
            </a-select>
          </a-form-item>
          <a-form-item label="推送机构">
            <SelectOrg
              v-if="this.user_ids.length <= 0"
              v-decorator="['org_ids', validatorRules.org_ids]"
              @change="changeTsjg"
            ></SelectOrg>
            <SelectOrg
              v-else
              v-model="org_ids"
              @change="changeTsjg"
            ></SelectOrg>
          </a-form-item>
          <a-form-item label="推送岗位">
            <selectJob
              v-if="this.user_ids.length <= 0"
              @change="changeTsgw"
              v-decorator="['post_ids', validatorRules.post_ids]"
            ></selectJob>
            <selectJob
              v-else
              v-model="post_ids"
              @change="changeTsgw"
            ></selectJob>
          </a-form-item>
          <a-form-item label="添加推送名单">
            <uploadFile
              :uploadAction="uploadNameList"
              @success="uploadNameListSuccess"
              @uploadLoainng="uploadLoainng"/>
            <span class="color-blue" v-if="user_ids.length > 0">
              已添加推送名单，再次上传将会覆盖原有名单！
            </span>

            <div>
              <a :href="userTemplate + '?title=推送名单模版.xlsx'" target="_blank">下载模版</a>
            </div>
          </a-form-item>
          <a-form-item label="手动指派">
            <a-switch v-model="sdzp"/>
          </a-form-item>
          <a-form-item label="本部审批人" v-if="trainingType === 2">
            <a-select placeholder="请选择本部审批人"
                      v-decorator="['admin_approver', validatorRules.admin_approver]"
                      :getPopupContainer="(triggerNode) => triggerNode.parentNode">
              <a-select-option v-for="item in approverList"
                               :value="item.id"
                               :key="item.id">
                {{ item.username }}
              </a-select-option>
            </a-select>
          </a-form-item>
          <a-form-item label="经销商审批岗位" v-if="trainingType === 2">
            <selectJob
              v-decorator="[
                'org_approval_post',
                validatorRules.org_approval_post
              ]"
              mode="default"
              @change="changeJxsSpgw"
              :optionsIds="post_ids"
            ></selectJob>
          </a-form-item>
        </div>
        <div class="wp100">
          <a-form-item label="证书发放">
            <a-switch v-model="isIssuance"/>
          </a-form-item>

          <template v-if="isIssuance">
            <a-form-item label="证书类型">
              <a-select placeholder="请选择证书类型"
                        v-decorator="[
                          'certificate_code_cate',
                          validatorRules.certificate_code_cate
                        ]"
                        :getPopupContainer="(triggerNode) => triggerNode.parentNode">
                <a-select-option v-for="item in categories"
                                 :value="item.id"
                                 :key="item.id">
                  {{ item.title }}
                </a-select-option>
              </a-select>
            </a-form-item>
            <a-form-item label="证书模板">
              <a-select placeholder="请选择证书模板"
                        v-decorator="['certificate_id', validatorRules.certificate_id]"
                        :getPopupContainer="(triggerNode) => triggerNode.parentNode">
                <a-select-option v-for="item in templates"
                                 :value="item.id"
                                 :key="item.id">
                  {{ item.title }}
                </a-select-option>
              </a-select>
            </a-form-item>
            <a-form-item label="证书领域">
              <a-select placeholder="请选择证书领域"
                        v-decorator="[
                          'certificate_code_domain',
                          validatorRules.certificate_code_domain
                        ]">
                <a-select-option v-for="item in fields"
                                 :value="item.id"
                                 :key="item.id">
                  {{ item.title }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </template>

          <a-form-item label="封面图">
            <upLoadPic
              v-decorator="['thumbnail', validatorRules.thumbnail]"
              :min-width="375"
              :min-height="252"
              @success="uploadImgSuccess"
              @uploadLoainng="uploadLoainng"
              :rate="rate"
              :imgUrl="imgUrl"
            ></upLoadPic>
            <span class="color-blue">图片比例为8:5，最小尺寸为375*252</span>
          </a-form-item>
          <a-form-item label="业务文件">
            <Uploader
              v-model="bussinessFileIds"
              :custom-file-list="bussinessFiles"
              :multiple="true"
              @uploadLoainng="uploadLoainng"
            >
              <a-button>
                <a-icon type="upload"/>
                上传
              </a-button>
            </Uploader>
          </a-form-item>
          <a-form-item label="阶段数" v-if="trainingType === 2">
            <a-select placeholder="请选择" v-model="jds" @change="handleChange">
              <a-select-option
                v-for="item in selectList5"
                :value="item"
                :key="item"
              >
                {{ item }}
              </a-select-option>
            </a-select>
            <div class="bg-grey mt-10">
              <div
                class="flex-box"
                v-for="(item, index) in stageList"
                :key="index"
              >
                <div class="lt">{{ index + 1 }}阶段天数</div>
                <div class="mid">
                  <a-input-number
                    class="w50"
                    v-model="item.stage_num"
                    :min="1"
                    :max="10"
                  />
                  天
                </div>
                <div class="rt" v-if="index">
                  间隔天数
                  <a-input-number
                    class="w50"
                    v-model="item.jg_num"
                    :min="1"
                    :max="30"
                  />
                  天
                </div>

                <div style="display: flex; justify-content: flex-end; flex: auto;">
                  <app-image-viewer v-if="$route.query.id"
                                    :url="item.qrImage"
                                    :hide-placeholder-img="true"
                                    class="viewer"
                                    @click.native.stop="">
                    <a>打卡二维码</a>
                  </app-image-viewer>
                </div>
              </div>
            </div>
          </a-form-item>
        </div>
      </div>
    </a-form>
    <saveButtons
      ref="saveButtons"
      @handleSubmit="handleSubmit"
      @saveAndNext="saveAndNext"
      :loading="loading"
    ></saveButtons>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import QRCode from 'qrcode';

import {
  baseInfoDetail,
  baseInfoAdd,
  baseInfoEdit,
  certTemplate,
} from '@/api/config';
import { ConfigMixin } from './mixins/configMixins';
import pick from 'lodash.pick';
import { getTrainingType } from './api/api';

import Uploader from '@/components/upload/Uploader';
import {
  getApprover,
  getCertificateFields,
  getCertificateTemplates,
} from './api/config.base';

export default {
  name: 'basicConfiguration',
  mixins: [ConfigMixin],
  components: {
    Uploader,
    uploadFile: () => import('@/components/upload/uploadFile'),
    selectJob: () => import('@/components/select/SelectJob'),
    SelectOrg: () => import('@/components/select/SelectOrg'),
    saveButtons: () => import('./components/saveButtons'),
    upLoadPic: () => import('./components/upLoadPic'),
  },
  computed: {
    ...mapState({
      trainingType: (state) => state.Offline.trainType,
      isAppoint: (state) => state.Offline.isAppoint,
      userTemplate: (state) => state.Offline.templates?.offline_import_push_user_template?.url || '',
    }),
  },
  watch: {
    trainingType: {
      immediate: true,
      handler(type) {
        if (+type === 2) {
          this.getApprover();
        }
      },
    },
  },
  data() {
    return {
      formItemLayout: {
        labelCol: { span: 10 },
        wrapperCol: { span: 14 },
      },
      form: this.$form.createForm(this),
      sdzp: false,
      loading: false,
      jds: 2,
      selectList: [],
      approverList: [],
      domainSelectList: [],
      templateSelectList: [],
      fieldNames: { label: 'title', value: 'id', children: 'childs' },
      selectList1: [
        {
          label: '线上',
          value: 1,
        },
        {
          label: '线下',
          value: 2,
        },
      ],
      selectList2: [
        {
          label: '线上判定',
          value: 1,
        },
        {
          label: '线下判定',
          value: 2,
        },
        {
          label: '全部判定',
          value: 3,
        },
        {
          label: '无需判定',
          value: 4,
        },
      ],
      selectList5: [1, 2, 3, 4, 5],
      validatorRules: {
        title: {
          rules: [{ required: true, message: '请输入业务名称!' }],
        },
        category_id: {
          rules: [{ required: true, message: '请选择业务类别!' }],
        },
        exec_method: {
          rules: [{ required: true, message: '请选择执行方式!' }],
        },
        post_ids: {
          rules: [{ required: false, message: '请选择推送岗位!' }],
        },
        time_determine: {
          rules: [{ required: true, message: '请选择时间判定!' }],
        },
        org_ids: {
          rules: [{ required: false, message: '请选择推送机构!' }],
        },
        user_ids: {
          rules: [{ required: true, message: '请添加推送名单!' }],
        },
        certificate_id: {
          rules: [{ required: true, message: '请选择证书模板!' }],
        },
        certificate_code_domain: {
          rules: [{ required: true, message: '请选择证书领域!' }],
        },
        certificate_code_cate: {
          rules: [{ required: true, message: '请选择证书类型!' }],
        },
        admin_approver: {
          rules: [{ required: true, message: '请选择本部审批人!' }],
        },
        org_approval_post: {
          rules: [{ required: true, message: '请选择经销商审批岗位!' }],
        },
        thumbnail: {
          rules: [{ required: true, message: '请上传封面图!' }],
        },
      },
      baseInfoDetail,
      baseInfoAdd,
      baseInfoEdit,
      uploadNameList: 'uploadNameList',
      stageList: [],
      thumbnail: '',
      business_files: '',
      rate: '1.6',
      imgUrl: '',
      haveMameList: false,
      categoryIds: [1, 2, 3],
      bussinessFileIds: '', // 业务文件资源 id 字符串
      bussinessFiles: [], // 业务文件列表
      user_ids: [],
      org_ids: '',
      post_ids: '',

      isIssuance: true, // 证书发放，是否发放证书
      categories: [], // 证书类型
      templates: [], // 证书模版
      fields: [], // 证书领域
    };
  },
  created() {
    this.getCertificateTemplates();
    this.getCertificateFields();
  },
  mounted() {
    this.isEdit ? this.getData() : this.setDefaultData();
  },
  methods: {
    /**
     * 获取证书模版
     * @returns {Promise<void>}
     */
    async getCertificateTemplates() {
      const data = await getCertificateTemplates({
        cert_type: 3, // 固定为 3
      });
      if (!data || data.error_code) {
        this.$message.error(data?.message || '获取证书模版失败');
        return;
      }
      this.templates = data.data || [];
    },
    /**
     * 获取证书分类和领域
     * @returns {Promise<void>}
     */
    async getCertificateFields() {
      const data = await getCertificateFields();
      if (!data || data.error_code) {
        this.$message.error(data?.message || '获取证书类型失败');
        return;
      }
      this.categories = data.data?.cate || [];
      this.fields = data.data?.domain || [];
    },
    /**
     * 辅导业务，获取本部审批人
     * @returns {Promise<void>}
     */
    async getApprover() {
      if (+this.trainingType !== 2) {
        return;
      }
      const data = await getApprover();
      if (!data || data.error_code) {
        return;
      }
      this.approverList = data.data || [];
    },

    async handleChange(val) {
      let list = this.stageList;
      if (list.length > +val) {
        list.length = +val;
      } else if (list.length < +val) {
        let num = +val - list.length;
        for (let i = 0; i < num; i++) {
          const str = { type: 2, trainingId: this.$route.query.id, stage: list.length + i + 1 }
          let obj = {
            stage_num: list.length === 1 && i === 0 ? 2 : 1,
            jg_num: 30,
            qrImage: this.$route.query.id ? (await QRCode.toDataURL(JSON.stringify(str), { margin: 2 }).catch(() => '') || '') : ''
          };
          this.stageList.push(obj);
        }
      }
    },
    async getData() {
      this.loading = true;
      const data = await baseInfoDetail({
        id: this.id,
      }).finally(() => (this.loading = false));
      if (!data || data.error_code) {
        this.$message.error(data.message || '获取数据失败');
        return;
      }
      let datas = data.data;
      if (data && datas) {
        datas.certificate_code_cate &&
        this.getTemSelectList(datas.certificate_code_cate);
        this.imgUrl = datas?.thumbnail_resource?.url;
        this.haveMameList = datas.user_ids ? true : false;
        this.bussinessFileIds = datas.business_files || '';
        this.bussinessFiles = datas.business_files_urls || [];
        this.isIssuance = +datas.is_cert_issue ? true : false;
        this.sdzp = +datas.is_appoint ? true : false;
        let obj = JSON.parse(datas.stage);
        this.jds = +obj.stage_num;

        this.stageList = []
        for(let i = 0; i < obj.stage_infos.length; i += 1) {
          const str = { type: 2, trainingId: this.$route.query.id, stage: i + 1 }
          this.stageList.push({
            stage_num: obj.stage_infos[i],
            jg_num: obj.interval[i - 1],
            qrImage: this.$route.query.id ? (await QRCode.toDataURL(JSON.stringify(str), { margin: 2 }).catch(() => '') || '') : ''
          })
        }
        this.thumbnail = datas.thumbnail;
        this.business_files = datas.business_files;
        this.post_ids = datas.post_ids;
        this.org_ids = datas.org_ids;
        this.user_ids = datas.user_ids
          ? datas.user_ids
          ?.split(',')
          ?.filter((s) => s !== '')
          ?.toString()
          : '';
        this.$nextTick(() => {
          datas.org_approval_post = datas.org_approval_post + '';
          this.form.setFieldsValue({
            ...datas,
            org_ids: datas.org_ids.toString(),
          });
          this.form.setFieldsValue({ thumbnail: this.thumbnail });
          let category_id = [];

          category_id.push(datas.categories.id);
          const category_ids = this.getCategories(datas.categories);
          this.form.setFieldsValue({
            exec_method: datas.exec_method,
            category_id: category_ids,
          });
        });
      } else {
        this.setDefaultData();
      }
    },
    getFileName(url) {
      return url.substring(url.lastIndexOf('/') + 1, url.length);
    },
    setDefaultData() {
      this.$nextTick(async () => {
        this.form.setFieldsValue(
          pick(
            'title',
            'category_id',
            'exec_method',
            'time_determine',
            'org_ids',
            'post_ids',
            'user_ids',
            'certificate_id',
            'certificate_code_domain',
            'certificate_code_cate',
            'org_approval_post',
            'admin_approver',
          ),
        );
        for (let i = 0; i < 2; i++) {
          let obj = {
            stage_num: i === 0 ? 4 : i === 1 ? 2 : 1,
          };
          if (i !== 0) {
            obj['jg_num'] = 30;
          }

          if(this.$route.query.id) {
            const str = { type: 2, trainingId: this.$route.query.id, stage: i + 1 }
            obj.qrImage = await QRCode.toDataURL(JSON.stringify(str), { margin: 2 }).catch(() => '') || ''
          }

          this.stageList.push(obj);
        }
      });
    },
    onSwitchChangezsff(checked) {
      let domain = sessionStorage.getItem('domainSelectList');
      this.getDomainSelectList();
      checked && !domain
        ? this.getDomainSelectList()
        : (this.domainSelectList = JSON.parse(domain));
    },
    handleSubmit(e) {
      e && e.preventDefault();
      this.form.validateFields((err, values) => {
        if (!err) {
          let str = '';
          this.isEdit
            ? ((str = 'baseInfoEdit'), (values.id = this.id))
            : (str = 'baseInfoAdd');
          values.stage = this.getStageList();
          values.is_appoint = this.sdzp ? 1 : 0;
          values.is_cert_issue = this.isIssuance ? 1 : 0;
          values.thumbnail = this.thumbnail;
          values.business_files = this.bussinessFileIds;
          values.category_id =
            values.category_id[values.category_id.length - 1] + '';
          values.org_ids = this.org_ids?.toString();
          values.post_ids = this.post_ids?.toString();
          values.user_ids = this.user_ids?.toString();
          this.save(str, values);
        }
      });
    },
    async save(str, params) {
      this.loading = true;
      const data = await this[str](params).finally(
        () => (this.loading = false),
      );

      if (!data || data.error_code) {
        this.$message.error({ content: data?.message || '保存失败' });
        return;
      }
      this.$message.success('保存成功');
      let id = params.id || data?.data?.id || null;
      if (!this.$route.query.id) {
        this.$router.replace({
          path: this.$route.path,
          query: {
            id: data.data.id,
          },
        });
      }
      this.getTrainingType(id);
      this.goNextStep('pageInfoConfiguration', id);
    },
    async getTrainingType(id) {
      const data = await getTrainingType({
        training_id: id,
      });
      this.$store.commit('Offline/updateTrainType', data.data?.type || 2);
      this.$store.commit('Offline/updateIsAppoint', data.data?.is_appoint || 0);
      this.$store.commit('Offline/updateStage', data.data?.stage || []);
    },
    getStageList() {
      let list = this.stageList;
      let stage_infos = [];
      let interval = [];
      list.forEach(
        (item) => (
          stage_infos.push(item.stage_num),
          item.jg_num && interval.push(item.jg_num)
        ),
      );
      return {
        stage_num: list.length,
        interval,
        stage_infos,
      };
    },
    handleChangeZslx(v) {
      this.getTemSelectList(v);
    },
    async getTemSelectList(cert_type = 3) {
      this.loading = true;
      const data = await certTemplate(cert_type).finally(
        () => (this.loading = false),
      );
      if (!data || data.error_code) {
        return;
      }
      if (data && data._status_code === 200) {
        sessionStorage.setItem('templateSelectList', JSON.stringify(data.data));
        this.templateSelectList = data.data;
      }
    },
    uploadYwwjSuccess(data) {
      this.business_files = data.file_resource_id;
    },
    uploadImgSuccess(data) {
      this.thumbnail = data.file_resource_id;
      this.form.setFieldsValue({ thumbnail: this.thumbnail });
    },
    uploadNameListSuccess(data) {
      this.form.setFieldsValue({ user_ids: data.list + '' });
      this.user_ids = data.list.toString();
      this.haveMameList = false;
    },
    changeJxsSpgw(v) {
      this.form.setFieldsValue({ org_approval_post: v });
    },
    changeTsgw(v) {
      this.form.setFieldsValue({ post_ids: v.toString() });
      this.post_ids = v.toString();
    },
    changeTsjg(v) {
      this.form.setFieldsValue({ org_ids: v.toString() });
      this.org_ids = v.toString();
    },
    onChange(value) {
      const type =
        this.selectList.filter((i) => i.id === value?.[0])?.[0]?.type ||
        undefined;
      this.$store.commit('Offline/updateTrainType', type);
    },
    getCategories(data) {
      const result = [];
      if (data?.parents) {
        if (data?.parents?.parents) {
          result.push(data?.parents?.parents.id);
        }
        result.push(data?.parents.id);
      }
      result.push(data?.id);
      return result;
    },
    uploadLoainng(value) {
      this.loading = value;
    },
  },
};
</script>

<style lang="less" scoped>
.flex-box {
  display: flex;
}

.wp100 {
  width: 100%;
}

.bg-grey {
  background-color: rgb(242, 242, 242);
  padding: 15px;

  .rt {
    flex: 1;
    white-space: nowrap;
  }

  .lt {
    padding-right: 10px;
  }
}

.text-center {
  text-align: center;
}

.color-blue {
  color: #048cdb;
}

.w50 {
  width: 60px;
}

::v-deep .select {
  min-height: 32px !important;
  height: auto !important;
}
</style>
