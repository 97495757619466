<template>
  <a-modal v-model="visible"
           :title="title"
           :mask-closable="false"
           :width="800"
           :centered="true"
           :confirm-loading="loading"
           @ok="change"
           @cancel="close">
    <div style="height: 200px;">
      <SelectPeople v-if="visible"
                    :only-selection="true"
                    @change="selectPeopleChange"/>
    </div>
  </a-modal>
</template>

<script>
import SelectPeople from '@/views/statistics/participation/components/SelectPeople';
import { getSettlement } from '../api/list';

export default {
  name: 'SelectDMSPeopleModal',
  components: {
    SelectPeople,
  },
  data() {
    return {
      loading: false,
      visible: false,

      title: '请选择人员信息表',
      trainingId: undefined,
      templateId: undefined,
    };
  },
  methods: {
    show({ trainingId } = {}) {
      this.trainingId = trainingId;

      this.visible = true;
    },
    close() {
      this.visible = false;

      this.trainingId = undefined;
      this.templateId = undefined;
    },

    selectPeopleChange(ev) {
      this.templateId = ev;
    },

    change() {
      this.settle({
        training_id: this.trainingId,
        template_id: this.templateId,
      });
    },

    async settle(params) {
      this.loading = true;
      const data = await getSettlement(params).finally(() => {
        this.loading = false;
      });
      if (!data || data.error_code) {
        this.$message.error(data?.message || '结算失败');
        return;
      }
      this.$message.success(data?.message || '结算成功');
      this.$emit('success');
      this.close();
    },
  },
};
</script>

<style scoped lang="scss">

</style>
