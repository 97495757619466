import request from '@/utils/Request';
import store from '@/store/index';
import { downloadFileExtra } from '@/utils/download';
import dayjs from 'dayjs'

// 获取培训类型信息
export function getTrainingType(params) {
  return request({
    url: '/offline/admin/training/type',
    method: 'get',
    params,
  });
}

// 获取培训可报名岗位
export function getTrainingPosts(params) {
  return request({
    url: '/offline/admin/training/posts',
    method: 'get',
    params,
  });
}

export function getTrainingOrgs(params) {
  return request({
    url: '/offline/admin/training/orgs',
    method: 'get',
    params,
  });
}
// 培训计划
export function getDesignplanDetail(params) {
  return request({
    url: '/offline/admin/designplan/detail',
    method: 'get',
    params,
  });
}
// 培训保存
export function getDesignplanSave(data) {
  return request({
    url: '/offline/admin/designplan/save',
    method: 'post',
    data,
  });
}
//获取有培训计划的培训
export function getTrainingPlan(params) {
  return request({
    url: '/public/conf/offline/getTrainingPlan',
    method: 'get',
    params,
  });
}
//培训计划导出列表
export function getTrainingPlanShow(params) {
  return request({
    url: '/offline/admin/designplan/show',
    method: 'get',
    params,
  });
}
//培训计划导出列表
export function trainingPlanExport(params) {
  return downloadFileExtra(
    {
      url: `/offline/admin/designplan/show?is_down=1&end_date=${params.end_date || ''}&start_date=${params.start_date || ''}&training_ids=${params.training_ids || ''}`,
      method: 'get',
      token: store.state.User.token || '',
      fileName: `培训计划_${dayjs().format('YYYYMMDDHHmm')}.xlsx`,
      params
    }
  );
}
