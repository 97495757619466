import { baseUrl } from '@/Config';
import { stringify } from 'qs';
import store from '@/store/index';
import request from '@/utils/Request';
import { downloadFileExtra } from '@/utils/download/index';

// 获取表单列表
export function getForms(params) {
  return request({
    url: '/offline/admin/forms',
    method: 'get',
    params,
  });
}

// 复制表单
export function copyForms(params) {
  return request({
    url: '/offline/admin/forms/show',
    method: 'get',
    params,
  });
}

// 新增表单
export function addForms(data) {
  return request({
    url: '/offline/admin/forms/add',
    method: 'POST',
    data,
  });
}

// 编辑表单
export function editForms(data) {
  return request({
    url: '/offline/admin/forms/edit',
    method: 'POST',
    data,
  });
}

// 启用&禁用表单
export function operateForms(data) {
  return request({
    url: '/offline/admin/forms/operate',
    method: 'POST',
    data,
  });
}

// 下载表单
export function downloadForm(params) {
  const path = `${baseUrl}/offline/admin/forms/download?${stringify(params)}`;
  return downloadFileExtra({
    url: path,
    method: 'GET',
    token: store.state.User.token || '',
  });
}
