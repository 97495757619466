<template>
  <div>
    <a-form layout="inline" class="form">
      <a-form-item label="名称">
        <a-input v-model="keyword" placeholder="请输入名称"/>
      </a-form-item>
      <a-form-item>
        <a-button type="primary" @click="onSearch">查询</a-button>
        <a-button @click="onReset">重置</a-button>
      </a-form-item>
      <a-form-item class="ml-40">
        <a-button type="primary" @click="onCreate">添加考试</a-button>
      </a-form-item>
    </a-form>

    <div class="ph-20">
      <a-table :columns="parsedColumns"
               :data-source="data"
               :pagination="false"
               :loading="loading"
               bordered
               row-key="id"
               class="table small-cell-table">
        <template slot="action" slot-scope="text, record">
          <a-button type="link" @click="onEdit(record)">编辑</a-button>
          <a-button type="link" @click="onDelete(record)">删除</a-button>
        </template>

        <template slot="footer">
          <Pagination v-if="pagination && pagination.total"
                      :pagination="pagination"
                      @change="onPageChange"
                      @showSizeChange="onSizeChange"/>
        </template>
      </a-table>
    </div>

    <div class="flex mt-20 mb-20">
      <a-button type="primary" @click="next">下一步</a-button>
      <a-button @click="back">取消</a-button>
    </div>

    <AddExamModal ref="addExamModalRef"
                  :training-id="id"
                  :training-type="trainingType"
                  @created="onSearch"
                  @edited="onRefresh"/>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import {
  getExamList,
  deleteExam,
} from './api/config.exam';

import Pagination, {
  getPagination,
  updatePagination,
} from '../../../../components/table/Pagination';
import AddExamModal from './components/AddExamModal';

const columns = [
  {
    title: '名称',
    dataIndex: 'title',
    scopedSlots: { customRender: 'title' },
  },
  {
    title: '考试时间',
    dataIndex: 'start_time',
    scopedSlots: { customRender: 'start_time' },
  },
  {
    title: '对应班次',
    dataIndex: 'classes_name',
    scopedSlots: { customRender: 'classes_name' },
  },
  {
    title: '岗位',
    dataIndex: 'post_name',
    scopedSlots: { customRender: 'post_name' },
  },
  {
    title: '辅导阶段',
    dataIndex: 'stage',
    customRender: (text) => `第${text}阶段`,
  },
  {
    title: '对应天',
    dataIndex: 'show_date',
    customRender: (text) => {
      if (!text) {
        return '--';
      }
      return text.split(',').filter(
        (i) => !!i,
      ).map(
        (i) => `第${i}天`,
      ).join(',');
    },
  },
  {
    title: '时长',
    dataIndex: 'time',
    scopedSlots: { customRender: 'time' },
    customRender: (text) => {
      return text + '分';
    },
  },
  {
    title: '次数',
    dataIndex: 'number',
    scopedSlots: { customRender: 'number' },
    customRender: (text) => {
      return text + '次';
    },
  },
  {
    title: '及格分',
    dataIndex: 'score',
    scopedSlots: { customRender: 'score' },
    customRender: (text) => {
      return text + '分';
    },
  },
  {
    title: '状态',
    dataIndex: 'status',
    scopedSlots: { customRender: 'status' },
    customRender: (text) => {
      let list = ['未开始', '进行中', '已结束'];
      return list[+text - 1] || '--';
    },
  },
  {
    width: '160px',
    title: '操作',
    dataIndex: 'action',
    scopedSlots: { customRender: 'action' },
  },
];

export default {
  name: 'Exam',
  components: {
    Pagination,
    AddExamModal,
  },
  computed: {
    ...mapState({
      trainingType: (state) => state.Offline.trainType,
    }),
    parsedColumns() {
      if (this.trainingType === 1) {
        return this.columns.filter(
          (i) => !['post_name', 'stage', 'show_date'].includes(i.dataIndex),
        );
      } else {
        return this.columns.filter(
          (i) => !['start_time', 'classes_name', 'status'].includes(i.dataIndex),
        );
      }
    },
  },
  data() {
    return {
      loading: false,
      id: '',

      keyword: '',

      columns,
      data: [],
      pagination: getPagination(),
    };
  },
  created() {
    this.id = this.$route.query.id;

    this.onSearch();
  },
  methods: {
    onSearch() {
      this.getData(1, this.pagination.defaultPageSize);
    },
    onReset() {
      this.keyword = '';

      this.onSearch();
    },
    onRefresh() {
      this.getData(this.pagination.current, this.pagination.pageSize);
    },
    onPageChange(page, pageSize) {
      this.getData(page, pageSize);
    },
    onSizeChange(current, size) {
      this.getData(1, size);
    },
    async getData(page, pageSize) {
      page = page || 1;
      pageSize = pageSize || this.pagination.defaultPageSize;

      if (this.loading) {
        return;
      }
      this.loading = true;

      const params = {
        page,
        per_page: pageSize,
        training_id: this.id,
        keyword: this.keyword,
      };

      const data = await getExamList(params).finally(() => {
        this.loading = false;
      });
      if (!data || data.error_code) {
        this.$message.error(data?.message || '获取数据失败');
        return;
      }
      this.data = data?.data?.data || data?.data || [];

      const pagination = data?.meta?.pagination || data?.data?.meta || {};
      this.pagination = updatePagination(this.pagination, pagination);
    },

    /**
     * 添加考试
     */
    onCreate() {
      this.onEdit({});
    },
    /**
     * 编辑考试
     * @param record
     */
    onEdit(record) {
      if (this.$refs.addExamModalRef?.show) {
        this.$refs.addExamModalRef.show({
          id: record?.id,
          trainingId: this.id,
          type: this.trainingType,
        });
      }
    },

    /**
     * 删除考试
     * @param record
     */
    onDelete(record) {
      this.$confirm({
        content: '确认删除该考试？',
        onOk: () => this.onConfirmDelete(record),
      });
    },
    async onConfirmDelete(record) {
      if (this.loading) {
        return;
      }
      this.loading = true;

      const data = await deleteExam({
        id: record.id,
      }).finally(() => {
        this.loading = false;
      });
      if (!data || data.error_code) {
        this.$message.error(data?.message || '删除失败');
        return;
      }
      this.$message.success(data.message || '删除成功');
      this.onRefresh();
    },

    /**
     * 下一步
     */
    next() {
      this.$router.replace({
        path: 'surveyConfiguration',
        query: { id: this.id },
      });
    },

    /**
     * 取消
     */
    back() {
      this.$confirm({
        title: '提示',
        content: '是否退出本页?',
        onOk: () => {
          this.$router.push('/index/offline/training/list');
        },
      });
    },
  },
};
</script>

<style scoped lang="scss">
.form {
  padding-left: 20px;
  padding-right: 20px;
  margin-top: 20px;
  margin-bottom: 20px;
}
</style>
