import request from '@/utils/Request';

// 讲师列表
export function getTeacherList(params) {
  return request({
    url: '/offline/admin/teachers',
    method: 'get',
    params,
  });
}

// 讲师列表
export function getTeacherListForSelect(params) {
  return request({
    url: '/offline/admin/teachers/select-list',
    method: 'get',
    params,
  });
}

// 讲师列表 —— 上架下架讲师 status 1: 上架 0: 下架
export function operateTeacher(data) {
  return request({
    url: '/offline/admin/teachers/operate',
    method: 'post',
    data,
  });
}

// 讲师列表 —— 删除讲师
export function deleteTeacher(data) {
  return request({
    url: '/offline/admin/teachers/delete',
    method: 'post',
    data,
  });
}

// 讲师列表 —— 预约审批配置 - 查询
export function getTeacherAppointment(params) {
  return request({
    url: '/offline/admin/training/teacher-book',
    method: 'get',
    params,
  });
}

// 讲师列表 —— 预约审批配置 - 保存
export function saveTeacherAppointment(data) {
  return request({
    url: '/offline/admin/training/teacher-book',
    method: 'post',
    data,
  });
}

// 获取讲师详情
export function getTeacherDetail(params) {
  return request({
    url: '/offline/admin/teachers/show',
    method: 'get',
    params,
  });
}

// 添加讲师
export function addTeacherDetail(data) {
  return request({
    url: '/offline/admin/teachers/add',
    method: 'post',
    data,
  });
}

// 修改讲师
export function editTeacherDetail(data) {
  return request({
    url: '/offline/admin/teachers/edit',
    method: 'post',
    data,
  });
}
